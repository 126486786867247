//Add All the wedding registry items here and allow people to be able to go to registry item detail page or from
//the list just go to the external website where the item is found (if the amount needed > 0 else all taken message
//shown)

import React, { Component } from "react";
import { fetchAllWeddingRegistryItems } from "../../services/registry/weddingRegistryService";
import { NavLink, Link } from "react-router-dom";

class WeddingRegistry extends React.Component {
	state = {
		weddingRegistryItems: [
			{
				wedding_registry_id: "",
				brand_name: "",
				product_name: "",
				product_price: 0,
				product_description: "",
				asin: "",
				company_found: "",
				barcode: "",
				registry_url: "",
				quantity_needed: 0,
				product_urls: [],
				recordCreatedDate: "",
				isDeleted: false,
			},
		],
	};
	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);

		const { data: weddingRegistryItems } = await fetchAllWeddingRegistryItems();
		console.log(weddingRegistryItems);
		this.setState({ weddingRegistryItems: weddingRegistryItems });
	}
	render() {
		const { weddingRegistryItems } = this.state;
		return (
			<React.Fragment>
				{/*CONTENT SECTION*/}
				<section id='content'>
					{/* CONTAINER-1 */}
					<div class='container weddingPartyHeaderSpacing'>
						{/* TITLE */}
						<div class='row'>
							<div class='col-md-offset-3 col-md-6 text-center'>
								<div class='page-title'>
									<h1>Wedding Registry</h1>

									<div class='heart-divider'>
										<span class='grey-line'></span>
										<i class='de-icon-heart pink-heart'></i>
										<i class='de-icon-heart grey-heart'></i>
										<span class='grey-line'></span>
									</div>
									<p>Wedding Registry Items for Keijaoh and Marlee.</p>
								</div>
							</div>
						</div>
						{/* END of TITLE */}
					</div>
					{/* END of CONTAINER-1 */}

					{/* CONTAINER-2 */}
					<div class='container'>
						{/* BLOG ITEM ROW 1 */}
						<div class='row'>
							{weddingRegistryItems.map((value, index) => (
								<React.Fragment>
									{/* BLOG-ITEM-1 */}
									<div key={index} class='blog-wrapper  col-md-4'>
										{/*PHOTO-ITEM*/}
										<div class='photo-item animation fadeIn'>
											{/*PHOTO*/}
											{value.product_urls.length > 0 && (
												<img
													src={value.product_urls[0]}
													alt=''
													class='hover-animation image-zoom-in'
													style={{ height: "300px" }}
												/>
											)}
											{/*PHOTO OVERLAY*/}
											<div class='layer wh100 hidden-black-overlay hover-animation fade-in'></div>
											{/*END of PHOTO OVERLAY*/}

											{/*ICON LINK*/}
											<div class='layer wh100 hidden-link hover-animation delay1 fade-in'>
												<div class='alignment'>
													<div class='v-align center-middle'>
														<Link to={`#`}>
															<div class='de-icon circle light medium-size inline'>
																<i class='de-icon-link'></i>
															</div>
														</Link>
													</div>
												</div>
											</div>
											{/*END of ICON LINK*/}
										</div>
										{/*END of PHOTO-ITEM*/}

										{/* TITLE & EXCERPT */}
										<div class='title-excerpt animation fadeIn'>
											<div class='de-icon circle very-small-size custom-heart-icon'>
												<i class='de-icon-heart'></i>
											</div>

											<h3>
												<Link to={`#`}>
													<span style={{ float: "center" }}>
														{value.product_name}
													</span>
												</Link>
											</h3>
											<h4>
												<span>Brand</span> <b>{value.brand_name}</b>
											</h4>
											<h4>
												<span>Availability</span>{" "}
												<b>
													{value.quantity_needed > 0 ? (
														<span style={{ color: "green" }}>
															Available to Buy
														</span>
													) : (
														<span style={{ color: "red" }}>All Taken</span>
													)}
												</b>
											</h4>
											<p>
												{value.product_description.length > 100
													? value.product_description.slice(0, 160) + "..."
													: value.product_description}
											</p>
											<div style={{ float: "right" }}>
												<h3>
													$<b>{value.product_price}</b>
												</h3>
											</div>
											<div>
												<Link
													className='btn btn-lg btn-success'
													to={{ pathname: `${value.registry_url}` }}
													target='_blank'>
													<span style={{ float: "center" }}>
														<b>Find Item</b>
													</span>
												</Link>
											</div>
										</div>
										{/* END of TITLE & EXCERPT */}
									</div>
									{/* END of BLOG-ITEM-1 */}
								</React.Fragment>
							))}
						</div>
						{/* END of BLOG ITEM ROW 1 */}
					</div>
					{/* END of CONTAINER */}
				</section>
				{/* END of CONTENT SECTION */}
			</React.Fragment>
		);
	}
}

export default WeddingRegistry;
