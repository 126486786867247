import React, { Component } from 'react'
import { fetchBride } from '../../services/brideGroom/bride_groom_services';
class AboutBride extends React.Component {
  state={
    bride:{
       adminId: "",
        firstName: "",
        userBioInfo: "",
        is_bride:false,
        lastName: "",
        profileImageUrl: "",
      
    }
  }
 async componentDidMount() {     
    //set at the top of the screen
    window.scrollTo(0, 0);

    const {data: bride} = await fetchBride();
  console.log(bride);
    this.setState({bride: bride})


 }
  render() {
    const {bride} = this.state;
    return (
      <React.Fragment>
        {/* MAIN SLIDER */}
        <section id="main-slider" class="fixed-height">
          {/* MAIN SLIDER TITLE OUTTER WRAPPER */}
          <div class="slide-title-outter-wrapper">
            {/* MAIN SLIDER TITLE INNER WRAPPER */}
            <div class="slide-title-inner-wrapper">
              {/* TITLE 1  */}
              <div class="slide-title align-bottom">
                <div class="container">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-6">
                      <div class="page-title">
                        <h1 class="animation bounceInLeft">{bride.firstName} {bride.lastName}</h1>

                        <div class="heart-divider animation fadeIn">
                          <span class="white-line"></span>
                          <i class="de-icon-heart pink-heart"></i>
                          <i class="de-icon-heart white-heart"></i>
                          <span class="white-line"></span>
                        </div>

                        <p class="animation delay1 bounceInRight" hidden>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Integer ultrices malesuada ante quis pharetra.
                          Nullam non bibendum dolor. Ut vel turpis
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END of MAIN SLIDER TITLE INNER WRAPPER */}
          </div>{' '}
          {/* END of MAIN SLIDER TITLE OUTTER WRAPPER */}
          {/* MAIN SLIDER IMAGES */}
          <div class="slides">
            {/* SLIDE IMAGE */}
            <img src={bride.profileImageUrl}   data-stellar-ratio="0.5"
              className="slide-image"/>

            <div class="slide-overlay" styles="opacity:0.2"></div>
          </div>{' '}
          {/* END of MAIN SLIDER IMAGES */}
        </section>
        {/* ENF of MAIN SLIDER */}
        {/*CONTENT SECTION*/}
        <section id="content">
          {/* ICON */}
          <div class="icon-title">
            <div class="de-icon circle light large-size aligncenter">
              <i class="de-icon-quote-1"></i>
            </div>
          </div>

          {/* CONTAINER */}
          <div
            class="container"
            style={{ paddingTop: '5%', paddingBottom: '5%' }}
          >
            {/* SOCIAL NETWORK ICON*/}
            <div class="row" hidden>
              <div class="col-md-12 text-center" styles="padding-bottom:50px;">
                <a href="#">
                  <div class="de-icon circle outline medium-size inline">
                    <i class="de-icon-facebook"></i>
                  </div>
                </a>

                <a href="#">
                  <div class="de-icon circle outline medium-size inline">
                    <i class="de-icon-gplus"></i>
                  </div>
                </a>

                <a href="#">
                  <div class="de-icon circle outline medium-size inline">
                    <i class="de-icon-twitter"></i>
                  </div>
                </a>
              </div>
            </div>
            {/* END of SOCIAL NETWORK ICON*/}

            {/*TEXT SECTION*/}
            <div class="row">
              <div class="col-md-offset-1 col-md-10">
                <p>
                <strong>I'm {bride.firstName} {bride.lastName}. </strong>{bride.userBioInfo}
                </p>
              </div>
        
            </div>
            {/*END of TEXT SECTION*/}
          </div>
          {/* END of CONTAINER */}
        </section>{' '}
        {/*END of CONTENT SECTION*/}
      </React.Fragment>
    )
  }
}

export default AboutBride
