import React from "react";

const RsvpSelect = ({ name, label, options, error, ...rest }) => {
	return (
		<div className='form-group'>
			<label htmlFor={name}>{label}</label>
			<select name={name} id={name} {...rest} className='form-control'>
				<option value='' />
				{options.map((option) => (
					<option key={option.rsvp_id} value={option.rsvp_id}>
						{option.rsvp_name}
					</option>
				))}
			</select>

			{error && <div className='alert alert-danger'>{error}</div>}
		</div>
	);
};

export default RsvpSelect;
