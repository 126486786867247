import React, { Component } from 'react'
import { fetchTargetWedding } from '../../../services/weddingparty/weddingPartyService'
import '../../../styletheme/css/weddingparty/weddingparty.css'

class WeddingPartyDetail extends React.Component {

  state={
		weddingParty:{
			weddingpartyId: "",
	        adminId: "",
	        firstName: "",
	        userBioInfo: "",
	        lastName: "",
	        weddingPartyRole: "",
	        profileImageUrl: "",
	        is_brides_maid: true,

		}

	  }
	  async componentDidMount() {     
		//set at the top of the screen
		window.scrollTo(0, 0);
    const weddingPartyId = this.props.match.params.party;
   
		const {data: weddingParty} = await fetchTargetWedding(weddingPartyId);
	  console.log(weddingParty);
		this.setState({weddingParty: weddingParty})
	
	
	
	 }
  render() {
    const {weddingParty} = this.state;

    return (
      <React.Fragment>
        	{/* MAIN SLIDER */}
    	<section id="main-slider" class="fixed-height">      	
            
            {/* MAIN SLIDER TITLE OUTTER WRAPPER */}
        	<div class="slide-title-outter-wrapper">
            	
            	{/* MAIN SLIDER TITLE INNER WRAPPER */}
				<div class="slide-title-inner-wrapper">
                            	
					{/* TITLE 1  */}
					<div class="slide-title align-bottom">
                                	
						<div class="container">
                        	<div class="row">
                        		<div class="col-md-offset-3 col-md-6">
                                            
                                	<div class="page-title">
                                  <h1 class="animation bounceInLeft">{weddingParty.firstName} {weddingParty.lastName}</h1>
                                		<h3 class="animation bounceInLeft">{weddingParty.weddingPartyRole}</h3>
                                        
                                		<div class="heart-divider animation fadeIn">
                                    		<span class="white-line"></span>
                                        	<i class="de-icon-heart pink-heart"></i>
                                        	<i class="de-icon-heart white-heart"></i>
                                        	<span class="white-line"></span>
                                    	</div>
                                    
                                 
                                        
                                    </div>
                                    
								</div>
							</div>
						</div>
                                    
					</div>
                                
				</div>{/* END of MAIN SLIDER TITLE INNER WRAPPER */}
                
			</div> {/* END of MAIN SLIDER TITLE OUTTER WRAPPER */}
                         
            {/* MAIN SLIDER IMAGES */}
            
  			<div class="slides">
    				{/* SLIDE IMAGE */}
                	<div data-stellar-ratio="0.5" class="slide-image" 
                  styles="background-image:url(http://placehold.it/1920x1080); background-position:top center"
                  
                  style={{
                    //height: '900px',
                   // width: '100%',
                   backgroundPosition: "top center",
                    backgroundImage: `url(${weddingParty.profileImageUrl})`,
                  
                  }}
                  
                  >
                    </div>
                    
                    {/* SLIDE OVERLAY  */}
                    <div class="slide-overlay" styles="opacity:0"> </div>                                  
    		</div>	{/* END of MAIN SLIDER IMAGES */}
            
		</section>{/* ENF of MAIN SLIDER */}
        
       
       {/*CONTENT SECTION*/}
        <section id="content">
          {/* ICON */}
          <div class="icon-title">
            <div class="de-icon circle light large-size aligncenter">
              <i class="de-icon-quote-1"></i>
            </div>
          </div>

          {/* CONTAINER */}
          <div
            class="container weddingPartyHeaderSpacing"
            styles={{ paddingBottom: '5%' }}
          >
            
            {/*TEXT SECTION*/}
            <div class="row">
              <div class="col-md-offset-1 col-md-10">
                <p>
                {weddingParty.userBioInfo}
                </p>
               
              </div>
       
            </div>
            {/*END of TEXT SECTION*/}
          </div>
          {/* END of CONTAINER */}
        </section>{' '}
        {/*END of CONTENT SECTION*/}
      </React.Fragment>
    )
  }
}

export default WeddingPartyDetail
