import React, { Component } from "react";

import notfound from "../../styletheme/images/404.svg";
import { Redirect, Link } from "react-router-dom";
import Form from "../reusable/other/form/form";
import Joi from "joi-browser";
import {
	authLoginGuest,
	getCurrentUser,
} from "../../services/authentication/guestauthen";

class Login extends Form {
	state = {
		couple_pic:
			"https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fcolor-56.jpeg?alt=media&token=9ecabd19-8e0c-43f6-a36a-c1e5d59268fc",
	};

	state = {
		data: {
			accessCode: "",
			firstName: "",
			lastName: "",
		},
		errors: {},
	};
	//Joi validation schema
	schema = {
		accessCode: Joi.string().min(7).required().label("Access Code"), //use joi in node as well
		firstName: Joi.string().required().label("First Name"),
		lastName: Joi.string().required().label("Last Name"),
	};

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
	}

	doSubmit = async () => {
		//login function
		try {
			const { data } = this.state;
			const invitePayLoad = {
				accessCode: data.accessCode,
				firstName: data.firstName,
				lastName: data.lastName,
			};

			console.log(invitePayLoad);
			await authLoginGuest(invitePayLoad);

			//get the current location
			const { state } = this.props.location;

			//force a reload of the page
			window.location = state ? state.from.pathname : "/attendance";
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.emailAddress = ex.response.data;
				//Update the UI
				this.setState({ errors });
			}
		}
	};

	render() {
		//if user logged in
		if (getCurrentUser()) return <Redirect to='/attendance' />;

		const { couple_pic } = this.state;
		//login page here
		return (
			<React.Fragment>
				{/* MAIN SLIDER */}
				<section id='main-slider' className='fixed-height'>
					{/* MAIN SLIDER TITLE OUTTER WRAPPER */}
					<div className='slide-title-outter-wrapper'>
						{/* MAIN SLIDER TITLE INNER WRAPPER */}
						<div className='slide-title-inner-wrapper'>
							{/* TITLE 1  */}
							<div className='slide-title align-bottom'>
								<div className='container'>
									<div className='row'>
										<div className='col-md-offset-2 col-md-8'>
											<div className='page-title'>
												<h1>Login Page</h1>

												<div className='heart-divider'>
													<span className='white-line'></span>
													<i className='de-icon-heart pink-heart'></i>
													<i className='de-icon-heart white-heart'></i>
													<span className='white-line'></span>
												</div>

												<p>
													Hi there, in order to respond to the RSVP. Please look
													for the little card found inside your envelope it will
													contain the name and access code to enter in the form
													below.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* END of MAIN SLIDER TITLE INNER WRAPPER */}
					</div>{" "}
					{/* END of MAIN SLIDER TITLE OUTTER WRAPPER */}
					{/* MAIN SLIDER IMAGES */}
					<div className='slides'>
						{/* SLIDE IMAGE */}

						<div
							data-stellar-ratio='0.5'
							className='slide-image'
							style={{
								height: "100%",
								width: "100%",
								backgroundImage: `url(${couple_pic})`,
								backgroundPosition: "top",
							}}></div>

						<div className='slide-overlay' styles='opacity:0.2'>
							{" "}
						</div>
					</div>{" "}
					{/* END of MAIN SLIDER IMAGES */}
				</section>
				{/* ENF of MAIN SLIDER */}

				<div className='slider-title-page'>
					{/*CONTENT SECTION*/}
					<section id='content'>
						{/* ICON */}
						<div className='icon-title'>
							<div className='de-icon circle light large-size aligncenter'>
								<i className='de-icon-mail-2'></i>
							</div>
						</div>

						{/* CONTAINER */}
						<div className='container'>
							{/*TEXT SECTION*/}
							<div className='row'>
								<div id='message' className='col-md-offset-1 col-md-10 message'>
									{/* Show Message */}
								</div>
							</div>

							<div className='row'>
								<div className='col-md-offset-1 col-md-5'>
									<h3 styles='margin-top:3px;'>
										PLEASE RESPOND BY 10th April, 2022
									</h3>
									<p>
										Once you have successfully logged in, you will be allowed to
										respond to the RSVP. You will have three options; depending
										on the option you select, you may have additional options.
										These options are not limited to adding plus one(s) and
										selecting menu options. Closer to the wedding date, you will
										be given additional choices.{" "}
									</p>
									<p>
										<b>Guest Portal Option (iOS)</b> <br />
										There is an iOS mobile portal for persons who would like to
										access the portal on the go. However, when the guest portal
										application was submitted to the App Store, Apple rejected
										it for various reasons, mainly because it was specific to
										our wedding and not open to others to use. As such, the
										rollout has been delayed; however, if you are willing to, we
										can give you access to the application through TestFlight to
										get early access to it. Instructions will be given as to how
										to do this, which in summary would be to provide us with
										your email address associated with your Apple ID download
										the Apple mobile application called TestFlight. Our app will
										show up there once you have accepted our invitation to test
										the app via your email.
									</p>
									<p>
										<b>Alternative Method (iOS)</b> <br />
										Alternatively, we have a link that you may use to download
										the application directly, but it may require you to update
										your phone settings. The alternate method to TestFlight is
										safe but requires a little more techy steps if your phone
										requires it; some phones don't just click install, and
										you're done.
									</p>
								</div>
								<form onSubmit={this.handleSubmit}>
									<div className='col-md-5'>
										<div className='rsvp-wrapper'>
											<div className='form-group page-subtitle'>
												<h2>Login Page</h2>
											</div>
											<div className='form-group'>
												{this.renderTextInputLabel(
													"firstName",
													"First Name",
													"text",
													" form-control form-control-lg ajax-input",
													"First Name",
													"form-label",
													true,
													"la la-envelope input-icon",
												)}
											</div>
											<div className='form-group'>
												{this.renderTextInputLabel(
													"lastName",
													"Last Name",
													"text",
													"form-control form-control-lg ajax-input",
													"Last Name",
													"form-label",
													true,
													"la la-envelope input-icon",
												)}
											</div>

											<div className='form-group'>
												{this.renderTextInputLabel(
													"accessCode",
													"Access Code",
													"password",
													"form-control form-control-lg ajax-input",
													"Access Code",
													"form-label",
													true,
													"la la-envelope input-icon",
												)}
											</div>

											<div className='form-group'>
												<br />
												{this.renderButton(
													"Login",
													"btn btn-secondary   button medium reverse",
												)}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						{/*END of TEXT SECTION*/}

						{/* END of CONTAINER */}
					</section>
					{/*END of CONTENT SECTION*/}
				</div>
			</React.Fragment>
		);
	}
}

export default Login;
