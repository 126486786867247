import React, { Component, useDebugValue } from 'react'
import { fetchBlogById } from '../../services/blogs/blogsService';
class BlogDetail extends React.Component {

  state={
		blog:{
			blogId: "",
			authorName: "",
			blogTitle: "",
			blogPromoUrl: "",
			blogOverview: "",
	        blogSections: [{
				section_id: "",
				section_type: "",
				content_body: ""
			}],
	        recordCreatedDate: ""

		}
	  }
	  async componentDidMount() {     
		//set at the top of the screen
		window.scrollTo(0, 0);
    const blogId = this.props.match.params.blogId;

		const {data: blog} = await fetchBlogById(blogId);
	  console.log(blog);
		this.setState({blog: blog})
	
	
	
	 }


  render() {

    const {blog} = this.state;

    return (
      <React.Fragment>
        {/* MAIN SLIDER */}
        <section id="main-slider" class="fixed-height">
          {/* MAIN SLIDER TITLE OUTTER WRAPPER */}
          <div class="slide-title-outter-wrapper">
            {/* MAIN SLIDER TITLE INNER WRAPPER */}
            <div class="slide-title-inner-wrapper">
              {/* TITLE 1  */}
              <div class="slide-title align-bottom">
                <div class="container">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-6">
                      <div class="page-title">
                        <h1 class="animation bounceInLeft">Blog Title Goes here</h1>

                        <div class="heart-divider animation fadeIn">
                          <span class="white-line"></span>
                          <i class="de-icon-heart pink-heart"></i>
                          <i class="de-icon-heart white-heart"></i>
                          <span class="white-line"></span>
                        </div>

                        <p class="animation delay1 bounceInRight">
                      {blog.blogOverview}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END of MAIN SLIDER TITLE INNER WRAPPER */}
          </div>{' '}
          {/* END of MAIN SLIDER TITLE OUTTER WRAPPER */}
          {/* MAIN SLIDER IMAGES */}
          <div data-stellar-ratio="0.5" class="slide-image" 
                  styles="background-image:url(http://placehold.it/1920x1080); background-position:top center"
                  
                  style={{
                    //height: '900px',
                   // width: '100%',
                   backgroundPosition: "top center",
                    backgroundImage: `url(${blog.blogPromoUrl})`,
                  
                  }}
                  
                  >
                    </div>

          {/* END of MAIN SLIDER IMAGES */}
        </section>
        {/* ENF of MAIN SLIDER */}
        {/*CONTENT SECTION*/}
        <section id="content">
          {/* ICON */}
          <div class="icon-title">
            <div class="de-icon circle light large-size aligncenter">
              <i class="de-icon-quote-1"></i>
            </div>
          </div>

          {/* CONTAINER */}
          <div
            class="container"
            style={{ paddingTop: '5%', paddingBottom: '5%' }}
          >
            
            {/*TEXT SECTION*/}
            <div class="row">
              <div class="col-md-offset-1 col-md-10">
                {blog.blogSections.length == 1 &&
                <React.Fragment>
                {blog.blogSections.map((value,index)=>(
                  <div class="col-md-10">
                  <p key={index}>
                     {value.content_body}
                  </p>
                  </div>
                ))}
                </React.Fragment>
                }
                {blog.blogSections.length > 1 &&
                <React.Fragment>
                {blog.blogSections.map((value,index)=>(
                    <div class="col-md-5">
                   <p key={index}>
                      {value.content_body}
                   </p>
                   </div>
                ))}
                </React.Fragment>
                }
       
              </div>
     
            </div>
            {/*END of TEXT SECTION*/}
          </div>
          {/* END of CONTAINER */}
        </section>{' '}
        {/*END of CONTENT SECTION*/}
      </React.Fragment>
    )
  }
}

export default BlogDetail
