import React, { Component } from "react";
import {
	getCurrentUser,
	getCurrentUserProfile,
} from "../../../services/authentication/guestauthen";
import Form from "../../reusable/other/form/form";
import Joi from "joi-browser";
import RsvpSelect from "../../reusable/other/select/rsvpselect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	fetchSaveTheDateInfo,
	respondToRsvp,
} from "../../../services/rsvp/rsvpservice";
import { Link } from "react-router-dom";
import { fetchMainGuestMenuById } from "../../../services/rsvp/guestManagement";

class MyDish extends Component {
	state = {
		currentProfile: {
			weddingGuestId: "",
			firstName: "",
			lastName: "",
		},

		errors: {},
		saveTheDateDetail: {
			isSent: false,
			sentMethod: "",
			dateSent: "",
			rsvp: "",
			isOnline: false,
			isInvitationSent: false,
			invitationSentMethod: "",
			dateInvitationSent: "",
		},

		mainGuestMainCouseMeal: {
			mealId: "",
			mealTitle: "",
			mealIngredients: "",
			mainMealPhotos: [""],
			specialRequestDetail: "",
			isAllergies: "",
			allergyInformation: "",
		},
	};

	//Joi validation schema

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
		//this.data.rsvp = "In-Person";
		//alert(this.data.rsvp);
		toast.configure();
		//toast.success("Error");
		//get the current user profile
		//when connected to the network
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log(`Profle ${profile.lastName}`);

		this.setState({ currentProfile: profile });

		//TODO
		//get the current SaveTheDateData for the current user
		const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
			profile.weddingGuestId,
		);
		console.log(saveTheDateDetail);
		this.setState({ saveTheDateDetail: saveTheDateDetail });
		/*const weddingPartyId = this.props.match.params.party;

	*/

		const { data: mainGuestMainCouseMeal } = await fetchMainGuestMenuById(
			profile.weddingGuestId,
		);
		console.log(mainGuestMainCouseMeal);
		this.setState({ mainGuestMainCouseMeal: mainGuestMainCouseMeal });
	}

	render() {
		const { currentProfile, mainGuestMainCouseMeal } = this.state;
		//TODO only for persons coming in person
		return (
			<React.Fragment>
				<div
					className='row'
					style={{
						//	display: "grid",
						gridTemplateColumns: "auto auto auto auto",
					}}>
					<div
						className=' slider-title-page'
						style={{
							width: "100%",
							height: "50%",
							textAlign: "center",
							display: "block",
						}}>
						<section
							id='content'
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								display: "inline-block",
								width: "100%",
								minWidth: "100px",
							}}>
							<h2>My Dish</h2>

							<h3>
								<span>
									{currentProfile.firstName} {currentProfile.lastName}'s Dish
								</span>
							</h3>
							{mainGuestMainCouseMeal.mealTitle === "" && (
								<div className='blog-wrapper col-md-offset-1 col-md-10'>
									<section>
										<h1>Empty</h1>
										<p>
											Please click "Select Main Course" to select your Dish.
										</p>

										<div style={{ textAlign: "right", padding: "1%" }}>
											<Link
												disabled
												to={"/selectmaindish"}
												className='btn btn-lg btn-success'>
												<b>Select Main Course</b>
											</Link>
										</div>
									</section>
								</div>
							)}

							{mainGuestMainCouseMeal.mealTitle !== "" && (
								<section>
									<div style={{ textAlign: "right", padding: "1%" }}>
										<Link
											disabled
											to={"/selectmaindish"}
											className='btn btn-lg btn-info'>
											<b>Change Main Course</b>
										</Link>
									</div>

									{/*
                          	<Link  disabled className='btn btn-lg btn-success'>
									<b>Select Main Course</b>
								</Link>  
                        */}
									{/* BLOG-ITEM-1 Make reuseable start */}
									<div className='blog-wrapper col-md-offset-1 col-md-5'>
										{/*PHOTO-ITEM*/}
										<div className='photo-item animation fadeIn'>
											{/*PHOTO*/}
											<div></div>
											{mainGuestMainCouseMeal.mainMealPhotos.length > 0 && (
												<img
													style={{
														width: "100%",
														minWidth: "100px",
													}}
													src={mainGuestMainCouseMeal.mainMealPhotos[0]}
													alt=''
													className='hover-animation image-zoom-in'
												/>
											)}
											{/*PHOTO OVERLAY*/}
											<div className='layer wh100 hidden-black-overlay hover-animation fade-in'></div>
											{/*END of PHOTO OVERLAY*/}

											{/*ICON LINK*/}
											<div className='layer wh100 hidden-link hover-animation delay1 fade-in'>
												<div className='alignment'>
													<div className='v-align center-middle'>
														<div className='de-icon circle light medium-size inline'>
															<i className='de-icon-link'></i>
														</div>
													</div>
												</div>
											</div>
											{/*END of ICON LINK*/}
										</div>
										{/*END of PHOTO-ITEM*/}

										{/* TITLE & EXCERPT */}
										<div
											className='title-excerpt animation fadeIn'
											style={{ textAlign: "left" }}>
											<div className='de-icon circle very-small-size custom-heart-icon'>
												<i className='de-icon-heart'></i>
											</div>
											<p>Main Course</p>
											<h3 style={{ textAlign: "left" }}>
												{mainGuestMainCouseMeal.mealTitle}
											</h3>
											<section>
												<h4 style={{ textAlign: "left" }}>
													<b>Special Request</b>
												</h4>
												<p>{mainGuestMainCouseMeal.specialRequestDetail}</p>
											</section>

											<section>
												<h4 style={{ textAlign: "left" }}>
													<b>Allergies</b>
												</h4>
												<p>{mainGuestMainCouseMeal.allergyInformation}</p>
											</section>
										</div>
										{/* END of TITLE & EXCERPT */}
									</div>

									{/* Make reusable*/}
								</section>
							)}
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default MyDish;
