import http from "../../services/httpService";
import apiEndpoint from "../../constants/config.json";

export async function respondToRsvp(_rsvpDetails) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint + "/guestManagement/api/update_guest_rsvp";

	return http.put(fullApiEndpoint, _rsvpDetails);
}

//get save the date information
export function fetchSaveTheDateInfo(weddingGuestId) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		`/guestManagement/api/fetch_guest_save_the_date_by_id/${weddingGuestId}`;
	return http.get(fullApiEndpoint);
}
