import React, { Component } from 'react'
import '../../styletheme/css/weddingparty/weddingparty.css'
import { NavLink, Link } from 'react-router-dom'
import { fetchAllBlogs } from '../../services/blogs/blogsService';

class Blogs extends React.Component {


	state={
		blogs:[{
			blogId: "",
			authorName: "",
			blogTitle: "",
			blogPromoUrl: "",
			blogOverview: "",
	        blogSections: [{
				section_id: "",
				section_type: "",
				content_body: ""
			}],
	        recordCreatedDate: ""

		}]
	  }
	  async componentDidMount() {     
		//set at the top of the screen
		window.scrollTo(0, 0);
	
		const {data: blogs} = await fetchAllBlogs();
	  console.log(blogs);
		this.setState({blogs: blogs})
	
	
	
	 }


  render() {
	  const {blogs} = this.state;
    return (
      <React.Fragment>
    {/*CONTENT SECTION*/}
        <section id="content">
        
        	{/* CONTAINER-1 */}
        	<div class="container weddingPartyHeaderSpacing">            
            	
                {/* TITLE */}
                <div class="row">
                    <div class="col-md-offset-3 col-md-6 text-center">
                    
                        <div class="page-title">
                        	<h1>BLOG</h1>
                                                       
                        	<div class="heart-divider">
                            	<span class="grey-line"></span>
                            	<i class="de-icon-heart pink-heart"></i>
                            	<i class="de-icon-heart grey-heart"></i>
                            	<span class="grey-line"></span>
                        	</div>
                            <p>
                        		Mindstone Blogs by Keijaoh and Marlee.
                       	 	</p>
						</div>
                        
                	</div>
				</div>{/* END of TITLE */}                
                
  			</div>{/* END of CONTAINER-1 */}
            
           	
            
            {/* CONTAINER-2 */}
            <div class="container">
                	
                {/* BLOG ITEM ROW 1 */}    
				<div class="row">
				{blogs .map((value,index)=>(
					<React.Fragment>

						{/* BLOG-ITEM-1 */}                        
						<div key={index} class="blog-wrapper col-md-offset-1 col-md-5">
                            
							{/*PHOTO-ITEM*/}
						   <div class="photo-item animation fadeIn">
								 
							 {/*PHOTO*/}
							 <img src={value.blogPromoUrl} alt="" class="hover-animation image-zoom-in"/>
											 
							 {/*PHOTO OVERLAY*/}
								<div class="layer wh100 hidden-black-overlay hover-animation fade-in">
							 </div>{/*END of PHOTO OVERLAY*/}
																					 
							 {/*ICON LINK*/}
							 <div class="layer wh100 hidden-link hover-animation delay1 fade-in">
								 <div class="alignment">
									   <div class="v-align center-middle">
											 
									  
												   
										 <Link to={`/blogdetail/${value.blogId}`}>
											 <div class="de-icon circle light medium-size inline">
												 <i class="de-icon-link"></i>
											 </div>
										 </Link>
												 
										</div>
								  </div>
							   </div>{/*END of ICON LINK*/}
							 
						 </div>{/*END of PHOTO-ITEM*/} 
										 
						 {/* TITLE & EXCERPT */}
						 <div class="title-excerpt animation fadeIn">
						 
							 <div class="de-icon circle very-small-size custom-heart-icon">
								 <i class="de-icon-heart"></i>
							 </div>
							 <h3><Link to={`/blogdetail/${value.blogId}`}>{value.blogTitle}</Link></h3>
							   <p>{value.blogOverview}</p>
							 
						 </div>{/* END of TITLE & EXCERPT */}
							 
					  </div>{/* END of BLOG-ITEM-1 */}  
					</React.Fragment>
					
					))}	    
                      
                    
                    
                
                     
				</div>{/* END of BLOG ITEM ROW 1 */}
                
                
                            
                    
			</div>{/* END of CONTAINER */}     
            
        </section>{/* END of CONTENT SECTION */}        
        
        


      </React.Fragment>
    )
  }
}

export default Blogs
