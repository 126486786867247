import React, { Component } from 'react'
import { fetchOurStory } from '../../services/ourStory/ourStoryManagment'


class OurStory extends React.Component {

  state={
    story:{
           blogId: "",
          blogTitle: "",
          blogPromoUrl: "",
          blogOverview: "",
          blogDetail: "",

      
    }
  }
 async componentDidMount() {     
  //set at the top of the screen
  window.scrollTo(0, 0);

  const {data: story} = await fetchOurStory();

    this.setState({story: story})


 }
  render() {

    const {story} = this.state;

    return (
      <React.Fragment>
        {/* MAIN SLIDER */}
        <section id="main-slider" class="fixed-height">
          {/* MAIN SLIDER TITLE OUTTER WRAPPER */}
          <div class="slide-title-outter-wrapper">
            {/* MAIN SLIDER TITLE INNER WRAPPER */}
            <div class="slide-title-inner-wrapper">
              {/* TITLE 1  */}
              <div class="slide-title align-bottom">
                <div class="container">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-6">
                      <div class="page-title">
                        <h1 class="animation bounceInLeft">How we met?</h1>

                        <div class="heart-divider animation fadeIn">
                          <span class="white-line"></span>
                          <i class="de-icon-heart pink-heart"></i>
                          <i class="de-icon-heart white-heart"></i>
                          <span class="white-line"></span>
                        </div>

                        <p class="animation delay1 bounceInRight">
                          {story.blogOverview}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END of MAIN SLIDER TITLE INNER WRAPPER */}
          </div>{' '}
          {/* END of MAIN SLIDER TITLE OUTTER WRAPPER */}
          {/* MAIN SLIDER IMAGES */}
          <div class="slides">
            {/* SLIDE IMAGE */}
            <img
              data-stellar-ratio="0.5"
              class="slide-image"
              src= {story.blogPromoUrl}
              style={{height: "100%",  objectFit: "cover"}}
            ></img>

            <div class="slide-overlay" styles="opacity:0.2">
              {' '}
            </div>
          </div>{' '}
          {/* END of MAIN SLIDER IMAGES */}
        </section>
        {/* ENF of MAIN SLIDER */}
        {/*CONTENT SECTION*/}
        <section id="content">
          {/* ICON */}
          <div class="icon-title">
            <div class="de-icon circle light large-size aligncenter">
              <i class="de-icon-quote-1"></i>
            </div>
          </div>

          {/* CONTAINER */}
          <div
            class="container"
            style={{ paddingTop: '5%', paddingBottom: '5%' }}
          >
            
            {/*TEXT SECTION*/}
            <div class="row">
              <div class="col-md-offset-1 col-md-10">
                <p>
              {story.blogDetail}
                </p>
               
              </div>
         
            </div>
            {/*END of TEXT SECTION*/}
          </div>
          {/* END of CONTAINER */}
        </section>{' '}
        {/*END of CONTENT SECTION*/}
      </React.Fragment>
    )
  }
}

export default OurStory
