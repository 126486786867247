

  import React, { Component } from 'react'
import { fetchAllMemories } from '../../services/memories/memoriesService';
import { fetchOurStory } from '../../services/ourStory/ourStoryManagment'
import Memories from './memories';


class Memory extends React.Component {

  state={
      memories:[{
        memoryId: "",
        src: "",
        width: 0,
        height: 0
    }]
  }
 async componentDidMount() {     
  //set at the top of the screen
  window.scrollTo(0, 0);

  const {data: memories} = await fetchAllMemories();
  //console.log(story);
  this.setState({memories: memories})


 }
  render() {

    const {memories} = this.state;

    return (
      <React.Fragment>
          <Memories memoriesImages={memories}/>
      </React.Fragment>
    )
  }
}

export default Memory
