import React, { Component } from 'react'
import { Slide } from 'react-slideshow-image'
import '../../styletheme/css/homecarousel/styles.css'
import 'react-slideshow-image/dist/styles.css'
import { fetchVenue } from '../../services/venue/venueManagement'
import { fetchBride, fetchGroom } from '../../services/brideGroom/bride_groom_services'


class TheVenue extends React.Component {

  //fetchVenue
  state={
    venueId: "",
    venueImages:[{
        url: "",
        caption: ""
    }],
    venueName: "",
    venueDescription: "",
    occasionLocations:[{
        locationName: "",
        locationAddress: "",//venue address
        locationDescription: "",
        locationImageUrl: "",

    }],
    interestingPlacesAround:[{
        interestId: "",
        interestTitle: "",
        interestAddress: "",
        interestDescription: ""
    }],
  bride:{
        adminId: "",
        firstName: "",
        userBioInfo: "",
        is_bride:false,
        lastName: "",
        profileImageUrl: "",
    
  },
  groom:{
     adminId: "",
        firstName: "",
        userBioInfo: "",
        is_bride:false,
        lastName: "",
        profileImageUrl: "",
    
  }
  }
  async componentDidMount() {     
    //set at the top of the screen
    window.scrollTo(0, 0);

    const {data: bride} = await fetchBride();
  
    this.setState({bride: bride})
    const {data: groom} = await fetchGroom();
    
    this.setState({groom: groom})

    const {data: venue} = await fetchVenue();
    console.log(venue);
    this.setState({venue: venue})


 }

  render() {
    const properties = {
        duration: 5000,
        autoplay: false,
        transitionDuration: 500,
        arrows: false,
        infinite: true,
        easing: 'ease',
        indicators: (i) => <div className="indicator">{i + 1}</div>,
      }

      const {venue,bride,groom} = this.state;
      return (
        <React.Fragment>
          {venue &&

          <React.Fragment>
          {/* MAIN SLIDER */}
        {venue != null && venue.venueImages.length > 0 &&
          <section id="main-slider" className="flexslider fullscreen">
            <Slide>
              {venue.venueImages.map((slideImage, index) => (
                <div className="each-slide" key={index}>
                  <div
                    style={{
                      height: '900px',
                      backgroundImage: `url(${slideImage.url})`,
                    }}
                  >
                    {/*<span>{slideImage.caption}</span>*/}
                    <div className="slide-title align-middle">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-offset-3 col-md-6 animation delay1 fadeInUp">
                            <div id="save-the-date">
                              <div id="save">{venue.venueName}</div>
                            
                            </div>
                           
                            <div className="heart-divider " styles="margin:0">
                              <div className="row">
                                <b>{groom.firstName}</b>
                                <i className="de-icon-heart pink-heart"></i>
                                <i className="de-icon-heart white-heart"></i>
  
                                <b>{bride.firstName}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slide>
          </section>
        }
          {/* END of MAIN SLIDER */}
          {/*About Venue: About, Address, Amentiites */}
          
                {/* ========================================================================= */}
                <section id="paragraph-lead">
                
                	{/* PARAGRAPH LEAD TITLE */}
                	<div className="row">
                   		<div className="col-md-offset-3 col-md-6 text-center">
                    
                        	<div className="section-title">
                        	{venue && 
                          	<h2>About {venue.venueName}</h2>
                          }                   
                        		<div className="heart-divider">
                            		<span className="grey-line"></span>
                            		<i className="de-icon-heart pink-heart"></i>
                            		<i className="de-icon-heart grey-heart"></i>
                            		<span className="grey-line"></span>
                        		</div>
							</div>
                        
                		</div>
					</div>{/* END of PARAGRAPH LEAD TITLE */}    
                
                	{/* PARAGRAPH LEAD */}
                	<div className="row">
                    	<div className="col-md-offset-1 col-md-10">
                          
                          <p>{venue.venueDescription}</p>
                      </div>
                    
					</div>{/* END of PARAGRAPH LEAD */}
                
                </section>{/* END of PARAGRAPH LEAD SECTION */}
                
                
     


                {/*Ceremony (The Gazebo Pic) and Reception Location  (The tent pic) */}
                {/*CONTENT SECTION*/}
        <section id="content">
          {/* SPACE DIVIDER */}
                {/* ========================================================================= */}
                <div className="row">
                	<div className="col-md-offset-1 col-md-10">
                    	<div className="space-divider"></div>
                    </div>
                </div>{/* END of SPACE DIVIDER */}
        	{/* CONTAINER */}
			<div className="container">
            
             	{/* ROW-1 */}
                <div className="row">
                {venue.occasionLocations.map((value,index)=>(
                	<div key={index} className="col-md-offset-1 col-md-5">
                    
                    	<div className="white-box-content-wrapper animation fadeInLeft">
                        	<h3>{value.locationName}</h3>
                            <span>{value.locationAddress}<br/><br/></span>
                    		  <p>
                            {value.locationDescription}  		
                          </p> 
                            <img src={value.locationImageUrl} alt="" className="fullwidth"/>
                        </div>
                    	
                    </div>
                ))}
                 
                    
				</div>{/*END of ROW-1*/}
                
                
                
                {/* ROW-2 */}
                <div className="row">
                
                	<div className="col-md-12 text-center animation fadeInUp" styles="padding-top:70px; padding-bottom:100px">
                    	<h3>INTEREST PLACE AROUND</h3>
                    </div>
                    
				        </div>{/*END of ROW-2*/}
                
    			
                {/* ROW-3 */}
                <div className="row">
                
                	<div className="col-md-offset-1 col-md-10">
                    
                    	{/* INTEREST AROUND ROW-1 */}
                    	<div className="row animation fadeIn">
                      {venue.interestingPlacesAround.map((value,index)=>(
                            
                            
                            <div key={index} className="col-md-6">
                            	<div className="interest-icon">
                                	<div className="de-icon circle small-size" styles="background-color:#0d9a48">
										<i className="de-icon-tree"></i>
            						</div>
                                </div>
                                <div className="interest-blurb">
                            		<span><strong>{value.interestTitle}</strong></span>
                                <p>
                                  <span>
                                <i className="de-icon-pin"></i>
                                 {value.interestAddress}
                                 </span>
                                  </p>
                                	<p>
                                    {value.interestDescription}
                                    </p>
                                </div>
                            </div>
                      ))}


                        </div>
                        
                    </div>
                    
				    </div>{/*END of ROW-2*/}
              {/* SPACE DIVIDER */}
                {/* ========================================================================= */}
                <div className="row">
                	<div className="col-md-offset-1 col-md-10">
                    	<div className="space-divider"></div>
                    </div>
                </div>{/* END of SPACE DIVIDER */}


            

                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d354.4803639547024!2d-68.5995941!3d44.702406!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cae5401d51d9691%3A0x44eaaff973f2d6f5!2sThe%20Lucerne%20Inn!5e0!3m2!1sen!2sus!4v1637772372071!5m2!1sen!2sus" 
                width="100%" height="500px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"loading="lazy"></iframe>
              
             
			</div> {/* END of CONTAINER */}
                        
        </section> {/*END of CONTENT SECTION*/}   
        </React.Fragment>
          }
      </React.Fragment>
    )
  }
}

export default TheVenue
