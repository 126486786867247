import React, { Component } from "react";
import "../../styletheme/css/weddingparty/weddingparty.css";
import { NavLink, Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
	fetchAllPlusOnesForGuest,
	removePlusOneGuestProfile,
} from "../../services/rsvp/guestManagement";
import {
	getCurrentUser,
	getCurrentUserProfile,
} from "../../services/authentication/guestauthen";

class PlusOneGuestManagement extends React.Component {
	state = {
		plusOneGuests: [
			{
				weddingGuestPlusOneId: "",
				weddingGuestId: "",
				firstName: "",
				lastName: "",
				relationToMainGuest: "",
				profileImageUrl: "",
				mainCourseMealItem: {
					mealId: "",
					mealTitle: "",
					mealIngredients: "",
					mainMealPhotos: [""],
					specialRequestDetail: "",
					isAllergies: "",
					allergyInformation: "",
				},
			},
		],
		currentProfile: {
			weddingGuestId: "",
			firstName: "",
			lastName: "",
		},
	};
	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
		//this.data.rsvp = "In-Person";
		//alert(this.data.rsvp);
		toast.configure();
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log(`Profle ${profile.lastName}`);

		this.setState({ currentProfile: profile });

		const { data: plusOneGuests } = await fetchAllPlusOnesForGuest(
			guest.weddingGuestId,
		);
		console.log(plusOneGuests);
		this.setState({ plusOneGuests: plusOneGuests });
	}

	//addplusoneguestfood
	goToAddPlusOneGuestFood(plusOneGuestProfile) {
		//alert(plusOneGuestProfile.weddingGuestPlusOneId);

		//go to add guest plus one food
		window.location = `/addplusoneguestfood/${plusOneGuestProfile.weddingGuestPlusOneId}`;
	}
	async removePlusOneGuest(plusOneGuestProfile) {
		try {
			//alert(plusOneGuestProfile.weddingGuestPlusOneId);
			await removePlusOneGuestProfile(
				plusOneGuestProfile.weddingGuestPlusOneId,
			);
			//if successful redirect to student dashboard where the courses registered for appears etc
			toast.success(`${plusOneGuestProfile.firstName} has been Removed!`);

			//refresh the page
			this.props.history.push(`/plusoneguest`);
			//set at the top of the screen
			window.scrollTo(0, 0);

			toast.configure();
			const guest = await getCurrentUser();

			this.setState({ guest });
			console.log(guest);

			const { data: plusOneGuests } = await fetchAllPlusOnesForGuest(
				guest.weddingGuestId,
			);

			this.setState({ plusOneGuests: plusOneGuests });
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.emailAddress = ex.response.data;

				console.log(ex.response.data);
				toast.error(ex.response.data);
				//update UI
				this.setState({ errors });
			}
		}
	}

	render() {
		const { plusOneGuests, currentProfile } = this.state;
		return (
			<React.Fragment>
				<div
					className='row'
					style={{
						//	display: "grid",
						gridTemplateColumns: "auto auto auto auto",
					}}>
					<div
						className=' slider-title-page'
						style={{
							width: "100%",
							height: "50%",
							textAlign: "center",
							display: "block",
						}}>
						<section
							id='content'
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								display: "inline-block",
								width: "100%",
								minWidth: "100px",
							}}>
							{/* Show all the plus one guests and add new plus one guest and their food */}
							<h1>
								{currentProfile.firstName + "'s"} {plusOneGuests.length} Guest
								{plusOneGuests.length > 1 && "s"}
							</h1>
							{plusOneGuests.length <= 0 && (
								<div className='blog-wrapper col-md-offset-1 col-md-10'>
									<section>
										<h1>Empty</h1>
										<p>
											You have the option to add 1 or more guest that will be
											accompanying you. You can add the guest by pressing the "+
											Plus One Guest" button
										</p>

										<div style={{ textAlign: "right", padding: "1%" }}>
											<Link
												disabled
												to={"/addplusoneguest"}
												className='btn btn-lg btn-success'>
												<b>+ Plus One Guest</b>
											</Link>
										</div>
									</section>
								</div>
							)}
							{plusOneGuests.length > 0 && (
								<div className='blog-wrapper col-md-offset-1 col-md-10'>
									<section>
										<p>
											You can add a guest by pressing the "+ Plus One Guest"
											button
										</p>

										<div style={{ textAlign: "right", padding: "1%" }}>
											<Link
												disabled
												to={"/addplusoneguest"}
												className='btn btn-lg btn-success'>
												<b>+ Plus One Guest</b>
											</Link>
										</div>
									</section>
								</div>
							)}
							{plusOneGuests.map((value, index) => (
								<React.Fragment>
									{/* BLOG-ITEM-1 Make reuseable start */}

									<div
										key={index}
										className='blog-wrapper col-md-offset-1 col-md-5'
										style={{ paddingTop: "5%" }}>
										{/*PHOTO-ITEM*/}
										<div className='photo-item animation fadeIn'>
											{/*PHOTO*/}

											{/*PHOTO OVERLAY*/}
											<div className='layer wh100 hidden-black-overlay hover-animation fade-in'></div>
											{/*END of PHOTO OVERLAY*/}

											{/*ICON LINK*/}
											<div className='layer wh100 hidden-link hover-animation delay1 fade-in'>
												<div className='alignment'>
													<div className='v-align center-middle'>
														<Link to={``}>
															<div className='de-icon circle light medium-size inline'>
																<i className='de-icon-link'></i>
															</div>
														</Link>
													</div>
												</div>
											</div>
											{/*END of ICON LINK*/}
										</div>
										{/*END of PHOTO-ITEM*/}

										{/* TITLE & EXCERPT */}
										<div
											className='title-excerpt animation fadeIn'
											style={{ textAlign: "left" }}>
											<div className='de-icon circle very-small-size custom-heart-icon'>
												<i className='de-icon-heart'></i>
											</div>

											<h2 style={{ textAlign: "left" }}>
												<Link>
													{value.firstName} {value.lastName}
												</Link>
											</h2>
											<section>
												<h4 style={{ textAlign: "left" }}>
													<b>Relation:</b>
												</h4>
												<p>{value.relationToMainGuest}</p>
											</section>
											<section>
												<Link
													onClick={() => this.removePlusOneGuest(value)}
													className='btn btn-lg btn-danger'>
													<b>Remove {value.firstName}</b>
												</Link>
											</section>

											<section style={{ textAlign: "right" }}>
												{value.mainCourseMealItem.mealTitle === "" && (
													<Link
														disabled
														onClick={() => this.goToAddPlusOneGuestFood(value)}
														className='btn btn-lg btn-success'>
														<b>+ Food</b>
													</Link>
												)}

												{value.mainCourseMealItem.mealTitle !== "" && (
													<React.Fragment>
														<Link
															disabled
															onClick={() =>
																this.goToAddPlusOneGuestFood(value)
															}
															className='btn btn-lg btn-info'>
															<b>Change Food</b>
														</Link>

														{/* BLOG-ITEM-1 Make reuseable start */}
														<div className='blog-wrapper '>
															{/*PHOTO-ITEM*/}
															<div className='photo-item animation fadeIn'>
																{/*PHOTO*/}
																<div>
																	{" "}
																	<br />
																</div>
																{value.mainCourseMealItem.mainMealPhotos && (
																	<img
																		style={{
																			minHeight: "25%",
																			maxHeight: "25%",
																			width: "100%",
																			minWidth: "100px",
																		}}
																		src={
																			value.mainCourseMealItem.mainMealPhotos[0]
																		}
																		alt=''
																		className='hover-animation image-zoom-in'
																	/>
																)}
																{/*PHOTO OVERLAY*/}
																<div className='layer wh100 hidden-black-overlay hover-animation fade-in'></div>
																{/*END of PHOTO OVERLAY*/}

																{/*ICON LINK*/}
																<div className='layer wh100 hidden-link hover-animation delay1 fade-in'>
																	<div className='alignment'>
																		<div className='v-align center-middle'>
																			<Link to={``}>
																				<div className='de-icon circle light medium-size inline'>
																					<i className='de-icon-link'></i>
																				</div>
																			</Link>
																		</div>
																	</div>
																</div>
																{/*END of ICON LINK*/}
															</div>
															{/*END of PHOTO-ITEM*/}

															{/* TITLE & EXCERPT */}
															{value.mainCourseMealItem.mealTitle !== "" &&
																value.mainCourseMealItem.mealTitle !==
																	undefined && (
																	<div
																		className='title-excerpt animation fadeIn'
																		style={{ textAlign: "left" }}>
																		<div className='de-icon circle very-small-size custom-heart-icon'>
																			<i className='de-icon-heart'></i>
																		</div>
																		<p>Main Course</p>
																		<h3 style={{ textAlign: "left" }}>
																			<Link to={``}>
																				{value.mainCourseMealItem.mealTitle}
																			</Link>
																		</h3>
																		<section>
																			<h4 style={{ textAlign: "left" }}>
																				<b>Special Request</b>
																			</h4>
																			<p>
																				{
																					value.mainCourseMealItem
																						.specialRequestDetail
																				}
																			</p>
																		</section>

																		<section>
																			<h4 style={{ textAlign: "left" }}>
																				<b>Allergies</b>
																			</h4>
																			<p>
																				{
																					value.mainCourseMealItem
																						.allergyInformation
																				}
																			</p>
																		</section>
																	</div>
																)}
															{/* END of TITLE & EXCERPT */}
														</div>

														{/* Make reusable*/}
													</React.Fragment>
												)}
											</section>
										</div>
										{/* END of TITLE & EXCERPT */}
									</div>

									{/* Make reusable*/}
								</React.Fragment>
							))}
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default PlusOneGuestManagement;
