import React, { Component } from 'react'
import { fetchGroom } from '../../services/brideGroom/bride_groom_services';


class AboutGroom extends React.Component {

  state={
    groom:{
       adminId: "",
        firstName: "",
        userBioInfo: "",
        is_bride:false,
        lastName: "",
        profileImageUrl: "",
      
    }
  }
 async componentDidMount() {     
    //set at the top of the screen
    window.scrollTo(0, 0);

    const {data: groom} = await fetchGroom();
  console.log(groom);
    this.setState({groom: groom})


 }
  render() {
    const {groom} = this.state;
    return (
      <React.Fragment>
        {/* MAIN SLIDER */}
        <section id="main-slider" className="fixed-height">
          {/* MAIN SLIDER TITLE OUTTER WRAPPER */}
          <div className="slide-title-outter-wrapper">
            {/* MAIN SLIDER TITLE INNER WRAPPER */}
            <div className="slide-title-inner-wrapper">
              {/* TITLE 1  */}
              <div className="slide-title align-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-md-offset-3 col-md-6">
                      <div className="page-title">
                        <h1 className="animation bounceInLeft">{groom.firstName} {groom.lastName}</h1>

                        <div className="heart-divider animation fadeIn">
                          <span className="white-line"></span>
                          <i className="de-icon-heart pink-heart"></i>
                          <i className="de-icon-heart white-heart"></i>
                          <span className="white-line"></span>
                        </div>

                        <p hidden className="animation delay1 bounceInRight">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Integer ultrices malesuada ante quis pharetra.
                          Nullam non bibendum dolor. Ut vel turpis
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END of MAIN SLIDER TITLE INNER WRAPPER */}
          </div>{' '}
          {/* END of MAIN SLIDER TITLE OUTTER WRAPPER */}
          {/* MAIN SLIDER IMAGES */}
          <div className="slides">
            {/* SLIDE IMAGE */}
          
            <img src={groom.profileImageUrl}   data-stellar-ratio="0.5"
              className="slide-image"/>
            <div className="slide-overlay" styles="opacity:0.2">
              {' '}
            </div>
          </div>{' '}
          {/* END of MAIN SLIDER IMAGES */}
        </section>
        {/* ENF of MAIN SLIDER */}
        {/*CONTENT SECTION*/}
        <section id="content">
          {/* ICON */}
          <div className="icon-title">
            <div className="de-icon circle light large-size aligncenter">
              <i className="de-icon-quote-1"></i>
            </div>
          </div>

          {/* CONTAINER */}
          <div
            className="container"
            style={{ paddingTop: '5%', paddingBottom: '5%' }}
          >
            {/* SOCIAL NETWORK ICON*/}
            <div className="row" hidden>
              <div className="col-md-12 text-center" styles="padding-bottom:50px;">
                <a href="#">
                  <div className="de-icon circle outline medium-size inline">
                    <i className="de-icon-facebook"></i>
                  </div>
                </a>

                <a href="#">
                  <div className="de-icon circle outline medium-size inline">
                    <i className="de-icon-gplus"></i>
                  </div>
                </a>

                <a href="#">
                  <div className="de-icon circle outline medium-size inline">
                    <i className="de-icon-twitter"></i>
                  </div>
                </a>
              </div>
            </div>
            {/* END of SOCIAL NETWORK ICON*/}

            {/*TEXT SECTION*/}
            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                
                <p>
                <strong>I'm {groom.firstName} {groom.lastName}. </strong>{groom.userBioInfo}
                </p>
            
              </div>
           
            </div>
            {/*END of TEXT SECTION*/}
          </div>
          {/* END of CONTAINER */}
        </section>{' '}
        {/*END of CONTENT SECTION*/}
      </React.Fragment>
    )
  }
}

export default AboutGroom
