import React, { Component, useState } from "react";
import { NavLink, Link } from "react-router-dom";
//import logo from '../../logo/logo@3x.png'
import styled from "styled-components";
import "../../index.css";

const NavBar = ({ guest, saveTheDateDetail }) => {
	//On load get the profile info from me

	//On load get the profile info from me
	//mobile start
	const COLORS = {
		primaryDark: "#f0394d",
		primaryLight: "#B6EDC8",
		menuBackground: "#eab676",
		textPrimaryColor: "#fff",
	};

	const MenuLabel = styled.label`
		background-color: ${COLORS.primaryDark};
		position: fixed;
		top: 0.5rem;
		right: 0.5rem;
		border-radius: 50%;
		height: 50px;
		width: 50px;
		cursor: pointer;
		z-index: 1000;

		box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
		text-align: center;
	`;

	const NavBackground = styled.div`
		position: fixed;
		top: 6.5rem;
		right: 6.5rem;
		background-image: radial-gradient(
			${COLORS.primaryDark},
			${COLORS.menuBackground}
		);
		height: 6rem;
		width: 6rem;
		border-radius: 50%;
		z-index: 600;
		transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
		transition: transform 0.8s;
	`;

	const Icon = styled.span`
		position: relative;
		background-color: ${(props) => (props.clicked ? "transparent" : "white")};
		width: 1.5rem;
		height: 1px;
		display: inline-block;
		margin-top: 1.5rem;
		transition: all 0.3s;
		&::before,
		&::after {
			content: "";
			background-color: white;
			width: 1.5rem;
			height: 1px;
			display: inline-block;
			position: absolute;
			left: 0;
			transition: all 0.3s;
		}
		&::before {
			top: ${(props) => (props.clicked ? "0" : "-0.8rem")};
			transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
		}
		&::after {
			top: ${(props) => (props.clicked ? "0" : "0.8rem")};
			transform: ${(props) =>
				props.clicked ? "rotate(-135deg)" : "rotate(0)"};
		}
		${MenuLabel}:hover &::before {
			top: ${(props) => (props.clicked ? "0" : "-1rem")};
		}
		${MenuLabel}:hover &::after {
			top: ${(props) => (props.clicked ? "0" : "1rem")};
		}
	`;

	const Navigation = styled.nav`
		height: 100vh;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 600;
		width: ${(props) => (props.clicked ? "100%" : "0")};
		opacity: ${(props) => (props.clicked ? "1" : "0")};
		transition: width 0.8s, opacity 0.8s;
	`;

	const List = styled.ul`
		position: absolute;
		list-style: none;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: 100%;
	`;
	const ItemLink = styled(NavLink)`
		display: inline-block;
		font-size: 2rem;
		font-weight: 500;
		text-decoration: none;
		color: ${COLORS.textPrimaryColor};
		padding: 0.5rem 2rem;
		background-image: linear-gradient(
			120deg,
			transparent 0%,
			transparent 50%,
			#fff 50%
		);
		background-size: 240%;
		transition: all 0.4s;
		&:hover,
		&:active {
			background-position: 100%;
			color: ${COLORS.primaryDark};
			transform: translateX(1rem);
		}
	`;

	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);

	//mobbile end

	return (
		<React.Fragment>
			{/* NAVIGATION */}
			<header id='nav-header'>
				<nav
					id='nav-bar'
					className='top-bar fluid-width block-color nav-center sticky-nav animation fadeInDown'>
					<div id='nav-wrapper'>
						{/* LOGO */}
						<div className='logo-wrapper'>
							{/* CSS LOGO */}
							<Link to='/'>
								<div className='css-logo rounded'>
									<div className='css-logo-text'>
										<strong> K</strong>
										<i className='de-icon-heart-1'></i>
										<strong>M</strong>
									</div>
								</div>
							</Link>

							{/* IMG LOGO 
                    	<div className="img-logo">
                    		<img src="images/slide1.jpg">
                    	</div>*/}
						</div>
						{/* END of LOGO */}

						{/*<div id="mobile-nav">
              <i className="de-icon-menu"></i>
                    </div>*/}
						<div></div>
						<div className='mobile-nav'>
							<MenuLabel
								id='mobile-nav'
								htmlFor='navi-toggle'
								onClick={handleClick}>
								<i style={{ color: "white" }} className='de-icon-menu'></i>
							</MenuLabel>
							<NavBackground clicked={click}>&nbsp;</NavBackground>
						</div>
						{!guest && (
							<ul id='nav-menu'>
								<li className='first-child'>
									<Link to='/'>Home</Link>
								</li>
								<li>
									<a href='#'>About Us</a>
									<ul>
										<li>
											<Link className='nav-link' to='/aboutgroom'>
												About Groom
											</Link>
										</li>
										<li>
											<Link to='/aboutbride'>About Bride</Link>
										</li>
									</ul>
								</li>
								<li>
									<Link to='/ourstory'>Our Story</Link>
								</li>
								<li>
									<Link to='/memories'>Memories</Link>
								</li>

								<li className='first-child split-margin '>
									<Link to='#'>The Wedding</Link>
									<ul>
										<li>
											<Link to='/bridesmaid'>Bridesmaids</Link>
										</li>
										<li>
											<Link to='/groomsmen'>Groomsmens</Link>
										</li>
										<li className=''>
											<Link to='/thevenue'>The Venue</Link>
										</li>
									</ul>
								</li>
								<li>
									<Link to='/blogs'>Blogs</Link>
								</li>
								<li>
									<Link to='/registry'>Registry</Link>
								</li>
								<li>
									<Link to='/login'>Sign In</Link>
								</li>
							</ul>
						)}
						{guest && (
							<ul id='nav-menu'>
								<li className='first-child'>
									<Link to='/registry'>Registry</Link>
								</li>

								<li className='first-child'>
									<Link to='/seatmap'>Seat Map</Link>
								</li>
								<li>
									<Link to='/blogs'>Blogs</Link>
								</li>

								<li className='first-child '>
									<Link to='/attendance'>RSVP</Link>
								</li>
								{saveTheDateDetail.rsvp == "In-Person" && (
									<React.Fragment>
										<li className='split-margin'>
											<Link to='/mydish'>My Dish</Link>
										</li>

										<li>
											<Link to='/plusoneguest'>Plus One</Link>
										</li>
									</React.Fragment>
								)}
								{saveTheDateDetail.rsvp == "Online" && (
									<React.Fragment>
										<li className=''>
											<Link to='/onlineguest'></Link>
										</li>
										<li className='split-margin'>
											<Link to='/onlineguest'>Online</Link>
										</li>
									</React.Fragment>
								)}
								<li>
									<Link to='/logout'>Sign Out</Link>
								</li>
							</ul>
						)}

						<div className='clearboth'></div>

						<div className='btn-box'>
							<Navigation clicked={click}>
								{!guest && (
									<List>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/'
												className='sub-menu-item'>
												Home
											</ItemLink>
										</li>

										<li>
											<ItemLink
												onClick={handleClick}
												to='/aboutgroom'
												className='sub-menu-item'>
												{" "}
												About Groom
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/aboutbride'
												className='sub-menu-item'>
												{" "}
												About Bride
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/memories'
												className='sub-menu-item'>
												Memories
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/ourstory'
												className='sub-menu-item'>
												Our Story
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/thevenue'
												className='sub-menu-item'>
												The Venue
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/bridesmaid'
												className='sub-menu-item'>
												Bridesmaids
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/groomsmen'
												className='sub-menu-item'>
												Groomsmens
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/blogs'
												className='sub-menu-item'>
												Blogs
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/registry'
												className='sub-menu-item'>
												Registry
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/login'
												className='sub-menu-item'>
												Login
											</ItemLink>
										</li>
									</List>
								)}
								{guest && (
									<List>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/'
												className='sub-menu-item'>
												Home
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/blogs'
												className='sub-menu-item'>
												Blogs
											</ItemLink>
										</li>
										{saveTheDateDetail.rsvp == "Online" && (
											<React.Fragment>
												<li>
													<ItemLink
														onClick={handleClick}
														to='/onlineguest'
														className='sub-menu-item'>
														Online
													</ItemLink>
												</li>
											</React.Fragment>
										)}
										<li>
											<ItemLink
												onClick={handleClick}
												to='/registry'
												className='sub-menu-item'>
												Registry
											</ItemLink>
										</li>
										<li>
											<ItemLink
												onClick={handleClick}
												to='/attendance'
												className='sub-menu-item'>
												RSVP
											</ItemLink>
										</li>
										{saveTheDateDetail.rsvp == "In-Person" && (
											<React.Fragment>
												<li>
													<ItemLink
														onClick={handleClick}
														to='/plusoneguest'
														className='sub-menu-item'>
														Plus One
													</ItemLink>
												</li>

												<li>
													<ItemLink
														onClick={handleClick}
														to='/mydish'
														className='sub-menu-item'>
														My Dish
													</ItemLink>
												</li>
											</React.Fragment>
										)}
										<li>
											{" "}
											<ItemLink
												onClick={handleClick}
												to='/logout'
												className='sub-menu-item'>
												Sign Out
											</ItemLink>
										</li>
									</List>
								)}
							</Navigation>
						</div>
					</div>
				</nav>
			</header>{" "}
			{/* END of NAVIGATION */}
		</React.Fragment>
	);
};

export default NavBar;
