import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import {
	getCurrentUser,
	getCurrentUserProfile,
} from "../../../services/authentication/guestauthen";
import Form from "../../reusable/other/form/form";
import Joi from "joi-browser";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchSaveTheDateInfo } from "../../../services/rsvp/rsvpservice";
import {
	fetchAllWeddingMenuOptions,
	findPlusOneProfile,
	sendMainGuestMenu,
	sendPlusOneGuestFoodMenu,
} from "../../../services/rsvp/guestManagement";
import { Link } from "react-router-dom";

class AddPlusOneGuestFood extends Form {
	state = {
		showMainDishForm: false,
		plusOneGuestProfile: {
			weddingGuestPlusOneId: "",
			weddingGuestId: "",
			firstName: "",
			lastName: "",
			relationToMainGuest: "",
			profileImageUrl: "",
			mainCourseMealItem: {
				mealId: "",
				mealTitle: "",
				mealIngredients: "",
				mainMealPhotos: [""],
				specialRequestDetail: "",
				isAllergies: "",
				allergyInformation: "",
			},
		},
		rsvp_options: [
			{
				rsvp_id: "0",
				rsvp_name: "In-Person",
			},
			{
				rsvp_id: "1",
				rsvp_name: "Online",
			},
			{
				rsvp_id: "2",
				rsvp_name: "Declines Regretfully",
			},
		],
		currentProfile: {
			weddingGuestId: "",
		},
		data: {
			foodSpecialRequest: "",
			foodAllergy: "",
		},
		errors: {},
		saveTheDateDetail: {
			isSent: false,
			sentMethod: "",
			dateSent: "",
			rsvp: "",
			isOnline: false,
			isInvitationSent: false,
			invitationSentMethod: "",
			dateInvitationSent: "",
		},

		foodOptions: [
			{
				menu_item_id: "",
				menu_item_name: "",
				menu_item_detail: "",
				menu_item_image_urls: [""],
				menu_item_category: "",
			},
		],

		selectFoodOption: {
			menu_item_id: "",
			menu_item_name: "",
			menu_item_detail: "",
			menu_item_image_urls: [""],
			menu_item_category: "",
		},
	};

	//Joi validation schema
	schema = {
		foodSpecialRequest: Joi.string().required().label("Food Special Request"),
		foodAllergy: Joi.string().required().label("Food Allergy"),
	};

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
		//this.data.rsvp = "In-Person";
		//alert(this.data.rsvp);
		toast.configure();
		//toast.success("Error");
		//get the current user profile
		//when connected to the network
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log(`Profle ${profile.lastName}`);

		this.setState({ currentProfile: profile });

		//TODO
		//get the current SaveTheDateData for the current user
		const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
			profile.weddingGuestId,
		);
		console.log(saveTheDateDetail);
		this.setState({ saveTheDateDetail: saveTheDateDetail });

		//fetch all the menu options

		//

		const { data: foodOptions } = await fetchAllWeddingMenuOptions();
		console.log(foodOptions);
		this.setState({ foodOptions: foodOptions });

		//get plus one weddingGuestPlusOneId profile
		const weddingGuestPlusOneId = this.props.match.params.weddingGuestPlusOneId;

		//toast.info(weddingGuestPlusOneId);

		const { data: plusOneGuestProfile } = await findPlusOneProfile(
			weddingGuestPlusOneId,
		);
		console.log(plusOneGuestProfile);
		this.setState({ plusOneGuestProfile: plusOneGuestProfile });
	}

	doSubmit = async () => {
		//handle the submission
		try {
			const { data, selectFoodOption, plusOneGuestProfile } = this.state;

			const mainCourseMealItem = {
				mealId: selectFoodOption.menu_item_id,
				mealTitle: selectFoodOption.menu_item_name,
				mealIngredients: selectFoodOption.menu_item_detail,
				mainMealPhotos: selectFoodOption.menu_item_image_urls,
				specialRequestDetail: data.foodSpecialRequest,
				isAllergies: data.foodAllergy !== "" ? true : false,
				allergyInformation: data.foodAllergy,
			};

			var main_guest_menu_detail = {
				weddingGuestPlusOneId: plusOneGuestProfile.weddingGuestPlusOneId,
				mainCourseMealItem: mainCourseMealItem,
			};
			console.log("Menu Detail Request");
			console.log(main_guest_menu_detail);

			await sendPlusOneGuestFoodMenu(main_guest_menu_detail);
			//if successful redirect to student dashboard where the courses registered for appears etc
			toast.success("Your Main Menu Successfully Received!");
			//go to student dashboard
			window.location = "/plusoneguest";
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.emailAddress = ex.response.data;

				console.log(ex.response.data);
				toast.error(ex.response.data);
				//update UI
				this.setState({ errors });
			}
		}
	};

	selectFoodMenu(menuDetail) {
		//	toast.info(`Selected ${menuDetail.menu_item_name}`);
		//hide the selection
		//this.state.showMainDishForm = true
		this.setState({ showMainDishForm: true });
		//updated the selectFoodOption
		this.setState({ selectFoodOption: menuDetail });

		//set at the top of the screen
		window.scrollTo(0, 0);
	}

	goBackToFoodOptions() {
		this.setState({ showMainDishForm: false });
	}

	render() {
		const {
			plusOneGuestProfile,
			foodOptions,
			showMainDishForm,
			selectFoodOption,
		} = this.state;
		//Need a TODO list Page Wedding and Registry Page
		return (
			<React.Fragment>
				{!showMainDishForm && (
					<React.Fragment>
						<div
							className='row'
							style={{
								//	display: "grid",
								gridTemplateColumns: "auto auto auto auto",
							}}>
							<div
								className=' slider-title-page'
								style={{
									width: "100%",
									height: "50%",
									textAlign: "center",
									display: "block",
								}}>
								<section
									id='content'
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										display: "inline-block",
										width: "100%",
										minWidth: "50px",
									}}>
									<h3 className='title' style={{ paddingTop: "1%" }}>
										Please click the "+ Food" button to select a menu item for{" "}
										{plusOneGuestProfile.firstName},
										<br /> then you will be asked to specify any special request
										and food alergies.
										<br />
										Then you may click "Send Order" to complete the selection
									</h3>
								</section>
							</div>
						</div>

						{/* Put the list here*/}

						{!showMainDishForm &&
							foodOptions.map((value, index) => (
								<React.Fragment>
									{/* BLOG-ITEM-1 Make reuseable start */}

									<div
										key={index}
										className='blog-wrapper col-md-offset-1 col-md-5'
										style={{ paddingTop: "5%" }}>
										{/*PHOTO-ITEM*/}
										<div className='photo-item animation fadeIn'>
											{/*PHOTO*/}

											{value.menu_item_image_urls.length > 0 && (
												<Slide>
													{value.menu_item_image_urls.map(
														(slideImage, index) => (
															<div className='each-slide' key={index}>
																<div
																	style={{
																		minheight: "500px",
																		maxheight: "100%",
																		backgroundImage: `url(${slideImage})`,
																		objectFit: "contain !Important",
																	}}></div>
															</div>
														),
													)}
												</Slide>
											)}
											{/*PHOTO OVERLAY*/}
											<div className='layer wh100 hidden-black-overlay hover-animation fade-in'></div>
											{/*END of PHOTO OVERLAY*/}

											{/*ICON LINK*/}
											<div className='layer wh100 hidden-link hover-animation delay1 fade-in'>
												<div className='alignment'>
													<div className='v-align center-middle'>
														<Link to={``}>
															<div className='de-icon circle light medium-size inline'>
																<i className='de-icon-link'></i>
															</div>
														</Link>
													</div>
												</div>
											</div>
											{/*END of ICON LINK*/}
										</div>
										{/*END of PHOTO-ITEM*/}

										{/* TITLE & EXCERPT */}
										<div
											className='title-excerpt animation fadeIn'
											style={{ textAlign: "left" }}>
											<div className='de-icon circle very-small-size custom-heart-icon'>
												<i className='de-icon-heart'></i>
											</div>

											<h2 style={{ textAlign: "left" }}>
												<Link
													disabled
													onClick={() =>
														toast.info(`Selected ${value.menu_item_name}`)
													}>
													{value.menu_item_name}
												</Link>
											</h2>
											<section>
												<h4 style={{ textAlign: "left" }}>
													<b>{value.menu_item_category}</b>
												</h4>
												<p>{value.menu_item_detail}</p>
											</section>

											<section style={{ textAlign: "right" }}>
												<Link
													disabled
													onClick={() => this.selectFoodMenu(value)}
													className='btn btn-lg btn-success'>
													<b>+ Food</b>
												</Link>
											</section>
										</div>
										{/* END of TITLE & EXCERPT */}
									</div>

									{/* Make reusable*/}
								</React.Fragment>
							))}
					</React.Fragment>
				)}

				{showMainDishForm && (
					<div
						className='row'
						style={{
							//	display: "grid",
							gridTemplateColumns: "auto auto auto auto",
						}}>
						<div
							className=' slider-title-page'
							style={{
								width: "100%",
								height: "50%",
								textAlign: "center",
								display: "block",
							}}>
							<section
								id='content'
								style={{
									marginLeft: "auto",
									marginRight: "auto",
									display: "inline-block",
									width: "70%",
									minWidth: "100px",
								}}>
								<div
									style={{
										textAlign: "right",
										paddingTop: "5%",
										paddingRight: "1%",
									}}>
									<Link
										className='btn btn-info'
										onClick={() => this.goBackToFoodOptions()}>
										<b>go Back to menu options </b>
									</Link>
								</div>
								{/* Dish start*/}
								<React.Fragment>
									{/* BLOG-ITEM-1 Make reuseable start */}
									<div className='row'>
										<div className='blog-wrapper col-md-offset-1 col-md-5'>
											<form
												onSubmit={this.handleSubmit}
												style={{ padding: "0%", width: "100%" }}>
												{this.renderMultiLineInput(
													"foodSpecialRequest",
													"Special Request",
													"text",
													" form-control form-control-lg ajax-input",
													"If no food special request, enter None in the textfield.Please enter any special request for this Meal, for example reduced salt.",
													"form-label",
													"5",
													true,
													"la la-envelope input-icon",
												)}

												{this.renderMultiLineInput(
													"foodAllergy",
													"Food Allergies",
													"text",
													" form-control form-control-lg ajax-input",
													"If no food alergies, enter None in the textfield. If any food allergies, please be as detailed and concise as you can so that the venue can prepare your meal keeping your food allergies in mind",
													"form-label",
													"5",
													true,
													"la la-envelope input-icon",
												)}

												{this.renderButton(
													"Send Order",
													"btn btn-success   button medium ",
												)}
											</form>
										</div>
										<div
											className='blog-wrapper col-md-offset-1 col-md-5'
											style={{ paddingTop: "0%" }}>
											{/*PHOTO-ITEM*/}
											<h3>
												You have selected {selectFoodOption.menu_item_name} for{" "}
												{plusOneGuestProfile.firstName}
											</h3>
											<div className='photo-item animation fadeIn'>
												{/*PHOTO*/}

												{selectFoodOption.menu_item_image_urls.length > 0 && (
													<Slide>
														{selectFoodOption.menu_item_image_urls.map(
															(slideImage, index) => (
																<div className='each-slide' key={index}>
																	<div
																		style={{
																			minheight: "500px",
																			maxheight: "100%",
																			backgroundImage: `url(${slideImage})`,
																			objectFit: "contain !Important",
																		}}></div>
																</div>
															),
														)}
													</Slide>
												)}
												{/*PHOTO OVERLAY*/}
												<div className='layer wh100 hidden-black-overlay hover-animation fade-in'></div>
												{/*END of PHOTO OVERLAY*/}

												{/*ICON LINK*/}
												<div className='layer wh100 hidden-link hover-animation delay1 fade-in'>
													<div className='alignment'>
														<div className='v-align center-middle'>
															<Link to={``}>
																<div className='de-icon circle light medium-size inline'>
																	<i className='de-icon-link'></i>
																</div>
															</Link>
														</div>
													</div>
												</div>
												{/*END of ICON LINK*/}
											</div>
											{/*END of PHOTO-ITEM*/}

											{/* TITLE & EXCERPT */}
											<div
												className='title-excerpt animation fadeIn'
												style={{ textAlign: "left" }}>
												<div className='de-icon circle very-small-size custom-heart-icon'>
													<i className='de-icon-heart'></i>
												</div>

												<h2 style={{ textAlign: "left" }}>
													<Link
														onClick={() =>
															toast.info(
																`Selected ${selectFoodOption.menu_item_name}`,
															)
														}>
														{selectFoodOption.menu_item_name}
													</Link>
												</h2>
												<section>
													<h4 style={{ textAlign: "left" }}>
														<b>{selectFoodOption.menu_item_category}</b>
													</h4>
													<p>{selectFoodOption.menu_item_detail}</p>
												</section>
											</div>
											{/* END of TITLE & EXCERPT */}
										</div>
									</div>
									{/* Make reusable*/}
								</React.Fragment>
								{/* Dish end*/}
							</section>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default AddPlusOneGuestFood;
