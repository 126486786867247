import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
/*

        <!-- Font -->
        <link href='http://fonts.googleapis.com/css?family=Oswald' rel='stylesheet' type='text/css'>
        <link href='http://fonts.googleapis.com/css?family=Arvo:400,700' rel='stylesheet' type='text/css'>
        <link href='http://fonts.googleapis.com/css?family=Pacifico' rel='stylesheet' type='text/css'>
        
        <!-- Theme CSS -->
    	<link href="css/style.css" rel="stylesheet" media="screen">


*/

import '../src/styletheme/css/bootstrap/bootstrap.min.css'

import '../src/styletheme/css/preloader.css'
import '../src/styletheme/css/preloader-default.css'
import '../src/styletheme/css/flexslider/flexslider.css'
import '../src/styletheme/css/animate/animate.css'
import '../src/styletheme/css/countdown/jquery.countdown.css'
import '../src/styletheme/css/magnific-popup/magnific-popup.css'
import '../src/styletheme/css/owlcarousel/owl.carousel.css'
import '../src/styletheme/css/owlcarousel/owl.theme.css'
import '../src/styletheme/css/fonts/fontello/css/fontello.css'
import '../src/styletheme/css/style.css'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
