import http from '../../services/httpService';
import apiEndpoint from '../../constants/config.json'

//create method create_new_check_out_order

export function fetchGroom(){
    const fullApiEndpoint = apiEndpoint.apiEndpoint + "/authentication/api/find_bride_groom/"+false;
    return http.get(fullApiEndpoint);
}

export function fetchBride(){
    const fullApiEndpoint = apiEndpoint.apiEndpoint + "/authentication/api/find_bride_groom/"+true;
    return http.get(fullApiEndpoint);
}