//onlineguest

import React, { Component } from "react";
import {
	getCurrentUser,
	getCurrentUserProfile,
} from "../../../services/authentication/guestauthen";

import Joi from "joi-browser";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	fetchSaveTheDateInfo,
	respondToRsvp,
} from "../../../services/rsvp/rsvpservice";
import { Link } from "react-router-dom";
import {
	fetchMainGuestMenuById,
	sendInterestInMobileApp,
} from "../../../services/rsvp/guestManagement";
import Form from "../../reusable/other/form/form";

class MobileApp extends Form {
	state = {
		currentProfile: {
			weddingGuestId: "",
			firstName: "",
			lastName: "",
		},

		data: {
			emailAddress: "",
		},
		errors: {},
		saveTheDateDetail: {
			isSent: false,
			sentMethod: "",
			dateSent: "",
			rsvp: "",
			isOnline: false,
			isInvitationSent: false,
			invitationSentMethod: "",
			dateInvitationSent: "",
		},

		mainGuestMainCouseMeal: {
			mealId: "",
			mealTitle: "",
			mealIngredients: "",
			mainMealPhotos: [""],
			specialRequestDetail: "",
			isAllergies: "",
			allergyInformation: "",
		},
	};

	//Joi validation schema

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
		//this.data.rsvp = "In-Person";
		//alert(this.data.rsvp);
		toast.configure();
		//toast.success("Error");
		//get the current user profile
		//when connected to the network
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log(`Profle ${profile.lastName}`);

		this.setState({ currentProfile: profile });

		//TODO
		//get the current SaveTheDateData for the current user
		const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
			profile.weddingGuestId,
		);
		console.log(saveTheDateDetail);
		this.setState({ saveTheDateDetail: saveTheDateDetail });
		/*const weddingPartyId = this.props.match.params.party;

	*/
	}

	schema = {
		emailAddress: Joi.string()
			.email({
				minDomainSegments: 2,
				tlds: { allow: ["com", "edu", "edu.jm"] },
			})
			.required()
			.label("Guest Email Address"),
	};
	doSubmit = async () => {
		//handle the submission
		try {
			const { data, currentProfile } = this.state;
			//update the save the date
			const guestInterest = {
				weddingGuestId: currentProfile.weddingGuestId,
				emailAddress: data.emailAddress,
			};

			await sendInterestInMobileApp(guestInterest);
			//if successful redirect to student dashboard where the courses registered for appears etc
			toast.success("Thank you! your Email Address Successfully Received!");
			//go to student dashboard
			//window.location = "/attendance";
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.emailAddress = ex.response.data;

				console.log(ex.response.data);
				toast.error(ex.response.data);
				//update UI
				this.setState({ errors });
			}
		}
	};

	render() {
		const { currentProfile } = this.state;
		//TODO only for persons coming in person
		return (
			<React.Fragment>
				<div
					className='row'
					style={{
						//	display: "grid",
						gridTemplateColumns: "auto auto auto auto",
					}}>
					<div
						className=' slider-title-page'
						style={{
							width: "100%",
							height: "50%",
							textAlign: "center",
							display: "block",
						}}>
						<section
							id='content'
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								display: "inline-block",
								width: "100%",
								minWidth: "100px",
							}}>
							<h2 style={{ paddingTop: "3%" }}>Mobile App Notice (iOS)</h2>

							<h3>
								<span>
									Hi {currentProfile.firstName} {currentProfile.lastName}
								</span>
							</h3>
							<div style={{ padding: "5%" }}>
								<p>
									I am (Keijaoh) currently working on an iOS mobile app to
									access the portal however, Apple had rejected the application,
									so i am in the process of making the changes, if your
									interested in being a part of the testing you may leave your
									email address and i will send you an invitation to test and
									use the mobile application. Thanks in advance.
								</p>
								<p>
									<b>
										<i>Preferred Method</i>
									</b>
									<br />
									<b>Instructions for Apple's Testflight option</b>
									<p>
										I will send you the invitation to download the application.
										You will just be required to download the "TestFlight"
										application that Apple built and is found on the App store.
										The "TestFlight" application gives you access to
										applications before they are fully released to the store and
										it doesnt require much more than accepting the invitation
										that Apple will send and accepting the application
									</p>
								</p>

								<form onSubmit={this.handleSubmit} style={{ padding: "0%" }}>
									{/*<RsvpSelect
								name='rsvpSelector'
								label={"RSVP Selector"}
								options={rsvp_options}
								style={{ width: "50%" }}
							/>*/}

									{this.renderTextInputLabel(
										"emailAddress",
										"Guest Email Address",
										"text",
										"form-control form-control-lg ajax-input ",
										"",
										"form-label",
										true,
										"la la-envelope input-icon",
									)}

									{this.renderButton(
										"Send Email to Keijaoh",
										"btn btn-success   button large ",
									)}
								</form>
								<p style={{ paddingTop: "3%" }}>
									<b>
										<i>Alternatively Method (advanced not ideal)</i>
									</b>{" "}
									<br />
									You can download the application directly, but not from the
									Apple App store but in order for it to work you may have to
									allow unknown sources. It is safe though.
									<br />
									<Link
										to={{ pathname: "https://appforshare.io/i/RFfh4c" }}
										className='btn btn-success btn-lg'
										target='_blank'>
										<b>
											<i>App for Share Link</i>
										</b>
									</Link>
								</p>
							</div>
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default MobileApp;
