import React from 'react'
import { Slide } from 'react-slideshow-image'
import '../../styletheme/css/style.css'
import '../../styletheme/css/homecarousel/styles.css'
import 'react-slideshow-image/dist/styles.css'
import { fetchBride, fetchGroom } from '../../services/brideGroom/bride_groom_services'
import { Link } from 'react-router-dom'
import { fetchLatestBlogs } from '../../services/blogs/blogsService'

class HomePage extends React.Component {

  state ={
    bride:{
       adminId: "",
        firstName: "",
        userBioInfo: "",
        is_bride:false,
        lastName: "",
        profileImageUrl: "",
      
    },
    groom:{
       adminId: "",
        firstName: "",
        userBioInfo: "",
        is_bride:false,
        lastName: "",
        profileImageUrl: "",
      
    },
    blogs:[{
			blogId: "",
			authorName: "",
			blogTitle: "",
			blogPromoUrl: "",
			blogOverview: "",
	        blogSections: [{
				section_id: "",
				section_type: "",
				content_body: ""
			}],
	        recordCreatedDate: ""

		}]
  }

  async componentDidMount() {     
    //set at the top of the screen
    window.scrollTo(0, 0);

    const {data: bride} = await fetchBride();
  
    this.setState({bride: bride})
    const {data: groom} = await fetchGroom();
    
      this.setState({groom: groom})

      
      const {data: blogs} = await fetchLatestBlogs(4);
    
      this.setState({blogs: blogs})

 }

  /*constructor() {
   super()
    this.slideRef = React.createRef()
    this.back = this.back.bind(this)
    this.next = this.next.bind(this)
    this.state = {
      current: 0,
   
  } }*/

  /*back() {
    this.slideRef.current.goBack()
  }

  next() {
    this.slideRef.current.goNext()
  }
*/
  render() {
    const properties = {
      duration: 5000,
      autoplay: false,
      transitionDuration: 500,
      arrows: false,
      infinite: true,
      easing: 'ease',
      indicators: (i) => <div className="indicator">{i + 1}</div>,
    }
    const slideImages = [
      {
        url:
          'https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fcolor-18.jpeg?alt=media&token=04fa2506-dd98-4164-bf2c-7aba9613b107',
        caption: 'Ring+Kiss',
      },
      { url: 'https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fcolor-56.jpeg?alt=media&token=9ecabd19-8e0c-43f6-a36a-c1e5d59268fc',
       caption: 'Holding In the Park' },
      {
        url:
          'https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fcolor-91.jpeg?alt=media&token=92f43eb0-6a39-484a-9614-7c537c814516',
        caption: 'Hug and Smile',
      },
      {
        url:
          'https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fcolor-99.jpeg?alt=media&token=1d9c8267-9315-4d7b-bd80-5295cc1efb31',
        caption: 'Twirl',
      },
    
      {
        url:
          'https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fbw-38.jpeg?alt=media&token=048e4951-b97a-4e72-a371-ee4354101be5',
        caption: 'By the Bridge',
      },
    ]
    const {bride, groom,blogs} = this.state;
    return (
      <React.Fragment>
        {/* MAIN SLIDER */}

        <section id="main-slider" className="flexslider fullscreen">
          <Slide>
            {slideImages.map((slideImage, index) => (
              <div className="each-slide" key={index}>
                <div
                  style={{
                    height: '900px',
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                >
                  {/*<span>{slideImage.caption}</span>*/}
                  <div className="slide-title align-middle">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-offset-3 col-md-6 animation delay1 fadeInUp">
                          <div id="save-the-date">
                            <div id="save">Save</div>
                            <div id="the-date">The Date</div>
                            <div id="date">
                              - 14th May
                              2022 -
                            </div>
                          </div>
                          <div
                            className="banner-text light medium"
                            styles="letter-spacing:3px"
                          >
                            <h4>*** WE ARE GETTING MARRIED ***</h4>
                          </div>
                          <div className="heart-divider " styles="margin:0">
                            <div className="row">
                              <b>Keijaoh</b>
                              <i className="de-icon-heart pink-heart"></i>
                              <i className="de-icon-heart white-heart"></i>

                              <b>Marlee</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slide>
        </section>

        {/* ENF of MAIN SLIDER */}

        {/*CONTENT SECTION*/}
        <section id="content">
          {/*COUPLE SECTION*/}
          <section id="couple">
            <div className="container">
              <div className="row">
                {/* SECTION TITLE */}
                <div className="col-md-offset-1 col-md-10 text-center">
                  <div className="section-title animation fadeInUp">
                    <h1>THE HAPPY COUPLE</h1>

                    <p className="blurb">
                    Keijaoh and Marlee welcomes you to our wedding website, to celebrate our union in marriage. We are so excited to be joined by our close friends, and family. 
                    </p>

                    <div className="heart-divider">
                      <span className="grey-line"></span>
                      <i className="de-icon-heart pink-heart"></i>
                      <i className="de-icon-heart grey-heart"></i>
                      <span className="grey-line"></span>
                    </div>
                  </div>
                </div>

                {/* SECTION CONTENT */}
                <div className="col-md-offset-1 col-md-5">
                  {/*PHOTO-ITEM*/}
                  <div className="photo-item animation fadeInLeft">
                    {/*HIS-PHOTO*/}
                    <img
                      src={groom.profileImageUrl}
                      alt=""
                      className="hover-animation image-zoom-in"
                    />

                    {/*PHOTO OVERLAY*/}
                    <div className="layer wh95 hidden-black-overlay hover-animation fade-in"></div>
                    {/*END of PHOTO OVERLAY*/}

                    {/*PHOTO CAPTION*/}
                    <div className="layer wh95 border-photo-caption hover-animation fade-out">
                      <div className="alignment">
                        <div className="v-align center-bottom">
                          <div>
                            <div className="heart-divider" styles="margin:0">
                              <i className="de-icon-heart pink-heart"></i>
                              <i className="de-icon-heart white-heart"></i>
                            </div>
                            <div
                              className="banner-text light small"
                              styles="letter-spacing:3px;"
                            >
                              <h4>*** THE GROOM ***</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*END of PHOTO CAPTION*/}

                    {/*ICON LINK*/}
                    <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                      <div className="alignment">
                        <div className="v-align center-middle">
                          <Link to="/aboutgroom"
                            className="de-button outline medium"
                          >
                            ABOUT HIM
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/*END of ICON LINK*/}
                  </div>
                  {/*END of PHOTO-ITEM*/}

                  <p className="couple-excerpt animation fadeInLeft">
                    <strong>I'm {groom.firstName} {groom.lastName}. </strong>{groom.userBioInfo}
                  </p>
                </div>

                <div className="col-md-5">
                  {/*PHOTO-ITEM*/}
                  <div className="photo-item animation fadeInRight">
                    {/*HER-PHOTO*/}
                    <img
                      src={bride.profileImageUrl}
                      alt=""
                      className="hover-animation image-zoom-in"
                    />

                    {/*PHOTO OVERLAY*/}
                    <div className="layer wh95 hidden-black-overlay hover-animation fade-in"></div>
                    {/*END of PHOTO OVERLAY*/}

                    {/*PHOTO CAPTION*/}
                    <div className="layer wh95 border-photo-caption hover-animation fade-out">
                      <div className="alignment">
                        <div className="v-align center-bottom">
                          <div>
                            <div className="heart-divider" styles="margin:0">
                              <i className="de-icon-heart pink-heart"></i>
                              <i className="de-icon-heart white-heart"></i>
                            </div>
                            <div
                              className="banner-text light small"
                              styles="letter-spacing:3px;"
                            >
                              <h4>*** THE BRIDE ***</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*END of PHOTO CAPTION*/}

                    {/*ICON LINK*/}
                    <div className="layer wh95 hidden-link hover-animation delay1 fade-in">
                      <div className="alignment">
                        <div className="v-align center-middle">
                          <Link to="/aboutbride"
                            className="de-button outline medium"
                          >
                            ABOUT HER
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/*END of ICON LINK*/}
                  </div>
                  {/*END of PHOTO-ITEM*/}

                  <p className="couple-excerpt animation fadeInRight">
                  <strong>I'm {bride.firstName} {bride.lastName}. </strong>{bride.userBioInfo}
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* LOCATION & COUNTDOWN SECTION */}
          <section id="location-countdown">
            <div
              className="image-divider auto-height"
              style={{backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Flucerneinn-propertyimagesign-1600width_orig.png?alt=media&token=dbf43518-ffff-40a4-b780-c1ae2c136731"})`,
                }}
              
              data-stellar-background-ratio="0.5"
            >
              <div className="divider-overlay"></div>

              {/* Use Center Middle Alignment to align middle the content for fixed-height parallax
                    <div className="alignment"> 
                    	<div className="v-align center-middle"> */}

              <div className="container">
                <div className="row">
                  <div className="col-md-offset-1 col-md-10 text-center">
                    <div
                      className="banner-text light medium animation fadeInUp"
                      styles="letter-spacing:3px;"
                    >
                      <h4>
                        *** LUCERNE INN, 2517 Main Rd. Dedham, ME 04429  ***
                      </h4>
                    </div>

                    <div className="animation fadeInUp">
                      <div id="counting-down">
                        <div id="counting">Counting</div>
                        <div id="down">Down</div>
                      </div>
                    </div>

                    <div className="heart-divider animation fadeInUp">
                      <span className="white-line"></span>
                      <i className="de-icon-heart pink-heart"></i>
                      <i className="de-icon-heart white-heart"></i>
                      <span className="white-line"></span>
                    </div>

                    <div
                      id="countdown"
                      className="simple-countdown animation fadeInUp"
                    ></div>
                  </div>
                </div>
              </div>

              {/*</div>
                   	</div>*/}
            </div>
          </section>

          {/* THE WEDDING SECTION */}
          <section hidden id="the-wedding">
            <div className="container">
              {/* ROW-1 */}
              <div className="row">
                {/* SECTION TITLE */}
                <div className="col-md-12 text-center">
                  <div className="section-title animation fadeInUp">
                    <h2>THE WEDDING</h2>

                    <p className="blurb">
                      Lorem ipsum dolor sit amet, consectetur adipiscing lorem
                      ipsum dolor sit amet, consectetur adipiscing
                    </p>

                    <div className="heart-divider">
                      <span className="grey-line"></span>
                      <i className="de-icon-heart pink-heart"></i>
                      <i className="de-icon-heart grey-heart"></i>
                      <span className="grey-line"></span>
                    </div>
                  </div>
                </div>
              </div>{' '}
              {/* END of ROW-1 */}
              {/* ROW-2 */}
              <div className="row">
                <div className="col-md-offset-1 col-md-10">
                  <div className="container-fluid">
                    <div className="row">
                      {/* ITEM-1 */}
                      <div className="wedding-item col-md-4 animation fadeIn">
                        {/*PHOTO-ITEM*/}
                        <div className="photo-item">
                          {/*PHOTO*/}
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/defaultImages%2FNoImageFound.jpg1_.png?alt=media&token=75d79486-de11-4096-8b7d-d8b1945d71cf"
                            alt=""
                            className="hover-animation image-zoom-in"
                          />

                          {/*PHOTO OVERLAY*/}
                          <div className="layer wh95 hidden-black-overlay hover-animation fade-in"></div>
                          {/*END of PHOTO OVERLAY*/}

                          <div className="layer wh95 border-photo-caption fade-out"></div>
                          {/*END of PHOTO CAPTION*/}

                          {/*ICON LINK*/}
                          <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                            <div className="alignment">
                              <div className="v-align center-middle">
                                <a
                                  href="location.html"
                                  className="de-button outline small"
                                >
                                  GET DIRECTION
                                </a>
                              </div>
                            </div>
                          </div>
                          {/*END of ICON LINK*/}
                        </div>
                        {/*END of PHOTO-ITEM*/}

                        {/* TITLE & EXCERPT */}
                        <div className="title-excerpt">
                          <h3>
                            <a href="location.html">LOCATION</a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Integer ultrices malesuada ante quis pharetra.
                            Nullam non{' '}
                          </p>
                        </div>
                        {/* END of TITLE & EXCERPT */}
                      </div>
                      {/* END of ITEM-1 */}
                      {/* ITEM-2 */}
                      <div className="wedding-item col-md-4 animation delay1 fadeIn">
                        {/*PHOTO-ITEM*/}
                        <div className="photo-item">
                          {/*PHOTO*/}
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/defaultImages%2FNoImageFound.jpg1_.png?alt=media&token=75d79486-de11-4096-8b7d-d8b1945d71cf"
                            alt=""
                            className="hover-animation image-zoom-in"
                          />

                          {/*PHOTO OVERLAY*/}
                          <div className="layer wh95 hidden-black-overlay hover-animation fade-in"></div>
                          {/*END of PHOTO OVERLAY*/}

                          <div className="layer wh95 border-photo-caption fade-out"></div>
                          {/*END of PHOTO CAPTION*/}

                          {/*ICON LINK*/}
                          <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                            <div className="alignment">
                              <div className="v-align center-middle">
                                <a
                                  href="gift.html"
                                  className="de-button outline small"
                                >
                                  GIFT LIST
                                </a>
                              </div>
                            </div>
                          </div>
                          {/*END of ICON LINK*/}
                        </div>
                        {/*END of PHOTO-ITEM*/}

                        {/* TITLE & EXCERPT */}
                        <div className="title-excerpt">
                          <h3>
                            <a href="gift.html">GIFT REGISTRY</a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Integer ultrices malesuada ante quis pharetra.
                            Nullam non{' '}
                          </p>
                        </div>
                        {/* END of TITLE & EXCERPT */}
                      </div>
                      {/* END of ITEM-2 */}
                      {/* ITEM-3 */}
                      <div className="wedding-item col-md-4 animation delay2 fadeIn">
                        {/*PHOTO-ITEM*/}
                        <div className="photo-item">
                          {/*PHOTO*/}
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/defaultImages%2FNoImageFound.jpg1_.png?alt=media&token=75d79486-de11-4096-8b7d-d8b1945d71cf"
                            alt=""
                            className="hover-animation image-zoom-in"
                          />

                          {/*PHOTO OVERLAY*/}
                          <div className="layer wh95 hidden-black-overlay hover-animation fade-in"></div>
                          {/*END of PHOTO OVERLAY*/}

                          <div className="layer wh95 border-photo-caption fade-out"></div>
                          {/*END of PHOTO CAPTION*/}

                          {/*ICON LINK*/}
                          <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                            <div className="alignment">
                              <div className="v-align center-middle">
                                <a
                                  href="accomodation.html"
                                  className="de-button outline small"
                                >
                                  ACCOMODATION
                                </a>
                              </div>
                            </div>
                          </div>
                          {/*END of ICON LINK*/}
                        </div>
                        {/*END of PHOTO-ITEM*/}

                        {/* TITLE & EXCERPT */}
                        <div className="title-excerpt">
                          <h3>
                            <a href="accomodation.html">ACCOMODATION</a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Integer ultrices malesuada ante quis pharetra.
                            Nullam non{' '}
                          </p>
                        </div>
                        {/* END of TITLE & EXCERPT */}
                      </div>{' '}
                      {/* END of ITEM-3 */}
                    </div>
                  </div>
                </div>
              </div>
              {/* END of ROW-2 */}
            </div>
            {/* END of CONTAINER */}
          </section>
          {/* Blog CONTENT SECTION*/}
          <section id="content">
            {/* CONTAINER-1 */}
            <div className="container">
              {/* TITLE */}
              <div className="row">
                <div className="col-md-offset-3 col-md-6 text-center">
                  <div className="page-title">
                    <h1>BLOGS</h1>

                    <div className="heart-divider">
                      <span className="grey-line"></span>
                      <i className="de-icon-heart pink-heart"></i>
                      <i className="de-icon-heart grey-heart"></i>
                      <span className="grey-line"></span>
                    </div>
                    <p>
                    Mindstone Blogs by Keijaoh and Marlee.
                    </p>
                  </div>
                </div>
              </div>
              {/* END of TITLE */}
            </div>
            {/* END of CONTAINER-1 */}
            
            {/* CONTAINER-2 */}
            <div className="container">
              {/* BLOG ITEM ROW 1 */}
              <div className="row">
                {/* BLOG-ITEM-1 */}

                {blogs.map((value,index)=>(
					<React.Fragment>

						{/* BLOG-ITEM-1 */}                        
						<div key={index} class="blog-wrapper col-md-offset-1 col-md-5">
                            
							{/*PHOTO-ITEM*/}
						   <div class="photo-item animation fadeIn">
								 
							 {/*PHOTO*/}
							 <img src={value.blogPromoUrl} alt="" class="hover-animation image-zoom-in"/>
											 
							 {/*PHOTO OVERLAY*/}
								<div class="layer wh100 hidden-black-overlay hover-animation fade-in">
							 </div>{/*END of PHOTO OVERLAY*/}
																					 
							 {/*ICON LINK*/}
							 <div class="layer wh100 hidden-link hover-animation delay1 fade-in">
								 <div class="alignment">
									   <div class="v-align center-middle">
											 
									  
												   
										 <Link to={`/blogdetail/${value.blogId}`}>
											 <div class="de-icon circle light medium-size inline">
												 <i class="de-icon-link"></i>
											 </div>
										 </Link>
												 
										</div>
								  </div>
							   </div>{/*END of ICON LINK*/}
							 
						 </div>{/*END of PHOTO-ITEM*/} 
										 
						 {/* TITLE & EXCERPT */}
						 <div class="title-excerpt animation fadeIn">
						 
							 <div class="de-icon circle very-small-size custom-heart-icon">
								 <i class="de-icon-heart"></i>
							 </div>
							 <h3><Link to={`/blogdetail/${value.blogId}`}>{value.blogTitle}</Link></h3>
							   <p>{value.blogOverview}</p>
							 
						 </div>{/* END of TITLE & EXCERPT */}
							 
					  </div>{/* END of BLOG-ITEM-1 */}  
					</React.Fragment>
					
					))}	   
              </div>
              {/* END of BLOG ITEM ROW 1 */}

            </div>
            {/* END of CONTAINER */}
          </section>
          {/* END of CONTENT SECTION */}
        </section>
      </React.Fragment>
    )
  }
}

export default HomePage
