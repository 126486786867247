import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

const GeneralFooter = (footer_img) => {
	return (
		<React.Fragment>
			{/* FOOTER SECTION */}
			<footer>
				<div className='each-slide'>
					<div
						style={{
							height: "900px",
							width: "100%",
							backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fbw-38.jpeg?alt=media&token=048e4951-b97a-4e72-a371-ee4354101be5"})`,
						}}>
						{/*<span>{slideImage.caption}</span>*/}
						<div className='slide-title align-middle'>
							<div className='container'>
								<div className='row'>
									<div className='col-md-offset-3 col-md-6 animation delay1 fadeInUp'>
										<div
											id='footer-couple-name'
											class='animation delay1 fadeInUp'>
											<div className='heart-divider ' styles='margin:0'>
												Made with <i class='de-icon-heart pink-heart'></i> by
												Keijaoh
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* END of FOOTER SECTION */}
		</React.Fragment>
	);
};

export default GeneralFooter;
