import http from '../../services/httpService';
import apiEndpoint from '../../constants/config.json'

//create method create_new_check_out_order

export function fetchAllBlogs(){

   const fullApiEndpoint = `${apiEndpoint.apiEndpoint}/blogManagement/api/fetch_all_blogs/`;
    return http.get(fullApiEndpoint);
}

export function fetchLatestBlogs(limit){

    const fullApiEndpoint = `${apiEndpoint.apiEndpoint}/blogManagement/api/fetch_latest_blogs/`+ limit;
     return http.get(fullApiEndpoint);
 }


export function fetchBlogById(blogId){

    const fullApiEndpoint = `${apiEndpoint.apiEndpoint}/blogManagement/api/fetch_blog_by_id/`+ blogId;
     return http.get(fullApiEndpoint);
 }