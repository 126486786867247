//onlineguest

import React, { Component } from "react";
import {
	getCurrentUser,
	getCurrentUserProfile,
} from "../../../services/authentication/guestauthen";

import Joi from "joi-browser";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	fetchSaveTheDateInfo,
	respondToRsvp,
} from "../../../services/rsvp/rsvpservice";
import { Link } from "react-router-dom";
import { fetchMainGuestMenuById } from "../../../services/rsvp/guestManagement";

class OnlineGuest extends Component {
	state = {
		currentProfile: {
			weddingGuestId: "",
			firstName: "",
			lastName: "",
		},

		errors: {},
		saveTheDateDetail: {
			isSent: false,
			sentMethod: "",
			dateSent: "",
			rsvp: "",
			isOnline: false,
			isInvitationSent: false,
			invitationSentMethod: "",
			dateInvitationSent: "",
		},

		mainGuestMainCouseMeal: {
			mealId: "",
			mealTitle: "",
			mealIngredients: "",
			mainMealPhotos: [""],
			specialRequestDetail: "",
			isAllergies: "",
			allergyInformation: "",
		},
	};

	//Joi validation schema

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
		//this.data.rsvp = "In-Person";
		//alert(this.data.rsvp);
		toast.configure();
		//toast.success("Error");
		//get the current user profile
		//when connected to the network
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log(`Profle ${profile.lastName}`);

		this.setState({ currentProfile: profile });

		//TODO
		//get the current SaveTheDateData for the current user
		const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
			profile.weddingGuestId,
		);
		console.log(saveTheDateDetail);
		this.setState({ saveTheDateDetail: saveTheDateDetail });
		/*const weddingPartyId = this.props.match.params.party;

	*/

		const { data: mainGuestMainCouseMeal } = await fetchMainGuestMenuById(
			profile.weddingGuestId,
		);
		console.log(mainGuestMainCouseMeal);
		this.setState({ mainGuestMainCouseMeal: mainGuestMainCouseMeal });
	}

	render() {
		const { currentProfile, mainGuestMainCouseMeal } = this.state;
		//TODO only for persons coming in person
		return (
			<React.Fragment>
				<div
					className='row'
					style={{
						//	display: "grid",
						gridTemplateColumns: "auto auto auto auto",
					}}>
					<div
						className=' slider-title-page'
						style={{
							width: "100%",
							height: "50%",
							textAlign: "center",
							display: "block",
						}}>
						<section
							id='content'
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								display: "inline-block",
								width: "100%",
								minWidth: "100px",
							}}>
							<h2>Online Guest Notice</h2>

							<h3>
								<span>
									Hi {currentProfile.firstName} {currentProfile.lastName}
								</span>
							</h3>
							<div style={{ padding: "5%" }}>
								<p>
									{" "}
									Marlee and I are so pleased that you will be joining us online
									for our special event(s). This page may look empty, but it
									will be populated with exciting things in the coming weeks.
									This includes but is not limited to, an online streaming of
									Marlee's{" "}
									<b>
										<i>Bridal Shower </i>
									</b>{" "}
									on{" "}
									<b>
										<i>
											Sunday the 10th April 2022 11:00 AM Eastern Daylight Time
											or New York Time
										</i>
									</b>
								</p>
								<p>
									Also, join our live streaming on this page on{" "}
									<b>
										<i>
											Saturday the 14th May 2022 at 4:00 PM Eastern Daylight
											Time or New York Time
										</i>
									</b>{" "}
									for our Wedding Day Ceremony. You will be able to send well
									wishes and be a part of our special day celebrations.
								</p>
								<p>
									Also feel free to check out our Wedding Registry, we
									appreciate your support <b>:)</b>
								</p>
								<p>
									<Link className='btn btn-success btn-lg' to='/registry'>
										<b>
											<i>Registry</i>
										</b>
									</Link>
								</p>
							</div>
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default OnlineGuest;
