//Add All the wedding registry items here and allow people to be able to go to registry item detail page or from
//the list just go to the external website where the item is found (if the amount needed > 0 else all taken message
//shown)

import React, { Component } from "react";
import { fetchAllWeddingRegistryItems } from "../../../services/registry/weddingRegistryService";
import { NavLink, Link } from "react-router-dom";
import {
	fetchAllGuestSeating,
	fetchAllGuestSeatingByTableName,
} from "../../../services/seating/seatmapService";

class SeatMap extends React.Component {
	state = {
		targetTableName: 13,
		seatMap: [
			/*{
				firstName: "",
				guestId: "",
				guestSeatingId: "",
				isDeleted: false,
				lastName: "",
				mealDetail: {
					mealTitle: "",
					specialRequestDetail: "",
					allergyInformation: "",
					mealId: "",
					allergyColorCode: "",
				},
				numberInSequence: 0,
				recordCreatedDate: "",
				tableId: "",
				tableName: 0,
				tableType: "",

				_id: "",
			},*/
		],
	};
	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);

		const { data: seatMap } = await fetchAllGuestSeatingByTableName(
			this.state.targetTableName,
		);
		console.log(seatMap);

		//group by func
		/*const res = seatMap.reduce((acc, curr) => {
			if (!acc[curr.tableName]) acc[curr.tableName] = []; //If this type wasn't previously stored
			acc[curr.tableName].push(curr);
			return acc;
		}, {});

		console.log(res);*/

		this.setState({ seatMap: seatMap });
	}
	render() {
		const { seatMap, targetTableName } = this.state;
		return (
			<React.Fragment>
				{/*CONTENT SECTION*/}
				<section id='content'>
					{/* CONTAINER-1 */}
					<div class='container weddingPartyHeaderSpacing'>
						{/* TITLE */}
						<div class='row'>
							<div class='col-md-offset-3 col-md-6 text-center'>
								<div class='page-title'>
									<h1>Seat Map</h1>

									<div class='heart-divider'>
										<span class='grey-line'></span>
										<i class='de-icon-heart pink-heart'></i>
										<i class='de-icon-heart grey-heart'></i>
										<span class='grey-line'></span>
									</div>
									<p>Wedding Registry Items for Keijaoh and Marlee.</p>
								</div>
							</div>
						</div>
						{/* END of TITLE */}
					</div>
					{/* END of CONTAINER-1 */}

					{/* CONTAINER-2 */}
					<div class='container'>
						{/* BLOG ITEM ROW 1 */}
						<div class='row'>
							<React.Fragment>
								{/* BLOG-ITEM-1 */}
								<div class='blog-wrapper  col-md-4'>
									{/*PHOTO-ITEM*/}
									<div class='photo-item animation fadeIn'>
										{/*PHOTO*/}

										{/*PHOTO OVERLAY*/}
										<div class='layer wh100 hidden-black-overlay hover-animation fade-in'></div>
										{/*END of PHOTO OVERLAY*/}

										{/*ICON LINK*/}
										<div class='layer wh100 hidden-link hover-animation delay1 fade-in'>
											<div class='alignment'>
												<div class='v-align center-middle'>
													<Link to={`#`}>
														<div class='de-icon circle light medium-size inline'>
															<i class='de-icon-link'></i>
														</div>
													</Link>
												</div>
											</div>
										</div>
										{/*END of ICON LINK*/}
									</div>
									{/*END of PHOTO-ITEM*/}

									{/* TITLE & EXCERPT */}
									<div class='title-excerpt animation fadeIn'>
										<div class='de-icon circle very-small-size custom-heart-icon'>
											<i class='de-icon-heart'></i>
										</div>

										<h4>
											<span>Table</span> <b>{targetTableName}</b>
										</h4>
										<h4>
											<React.Fragment>
												<span>Guests</span>
												<br />
												<ol>
													{seatMap.map((value, index) => (
														<li key={index}>
															{value.firstName} {value.lastName}
															<br />
															<b>Allergies</b>
															<br />
															{value.mealDetail.allergyInformation}
														</li>
													))}
												</ol>
											</React.Fragment>
										</h4>
										<p></p>
									</div>
									{/* END of TITLE & EXCERPT */}
								</div>
								{/* END of BLOG-ITEM-1 */}
							</React.Fragment>
						</div>
						{/* END of BLOG ITEM ROW 1 */}
					</div>
					{/* END of CONTAINER */}
				</section>
				{/* END of CONTENT SECTION */}
			</React.Fragment>
		);
	}
}

export default SeatMap;
