//show the seating for all persons at the wedding (no nav bar to see it)
//each guest should see their table

import http from "../../services/httpService";
import apiEndpoint from "../../constants/config.json";

//create method create_new_check_out_order

export function fetchAllGuestSeating() {
	const fullApiEndpoint = `${apiEndpoint.apiEndpoint}/guestSeatManagment/api/fetch_all_guest_and_tables`;
	return http.get(fullApiEndpoint);
}

//fetch_all_guest_by_table_name/:tableName
export function fetchAllGuestSeatingByTableName(tableName) {
	const fullApiEndpoint = `${apiEndpoint.apiEndpoint}/guestSeatManagment/api/fetch_all_guest_by_table_name/${tableName}`;
	return http.get(fullApiEndpoint);
}
