import http from "../httpService";
import apiEndpoint from "../../constants/config.json";
import jwtDecode from "jwt-decode";

//declaring the constants
const tokenKey = "mk_wedding_token";

http.setJwt(getJwt());

//login method
//http://localhost:3001/authentication/api/login_student
export async function authLoginGuest(accountPayLoad) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint + "/guestManagement/api/login_guest_user";
	//emailAddress
	//passwordTxt
	const { data: jwt } = await http.post(fullApiEndpoint, accountPayLoad); //need to get the response

	localStorage.setItem(tokenKey, jwt);
}

export function loginWithJwt(jwt) {
	localStorage.setItem(tokenKey, jwt);
}

export function logout() {
	localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
	try {
		//get current user
		const jwt = localStorage.getItem(tokenKey);
		return jwtDecode(jwt);
	} catch (ex) {
		return null;
	}
}

export function getJwt() {
	return localStorage.getItem(tokenKey);
}

//get current user profile details

export function getCurrentUserProfile(weddingGuestId) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint + `/guestManagement/api/me/${weddingGuestId}`;
	return http.get(fullApiEndpoint);
}
