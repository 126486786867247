import React, { Component } from "react";
import {
	getCurrentUser,
	getCurrentUserProfile,
} from "../../services/authentication/guestauthen";
import Form from "../reusable/other/form/form";
import Joi from "joi-browser";
import RsvpSelect from "../reusable/other/select/rsvpselect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	fetchSaveTheDateInfo,
	respondToRsvp,
} from "../../services/rsvp/rsvpservice";
import store_img_1 from "../../styletheme/images/store/1.png";
import store_img_2 from "../../styletheme/images/store/2.png";
import { NavLink, Link } from "react-router-dom";

class AttendanceManagement extends Form {
	state = {
		rsvp_options: [
			/*	{
				rsvp_id: "0",
				rsvp_name: "In-Person",
			},*/
			{
				rsvp_id: "1",
				rsvp_name: "Online",
			},
			{
				rsvp_id: "2",
				rsvp_name: "Declines Regretfully",
			},
		],
		currentProfile: {
			weddingGuestId: "",
		},
		data: {
			guestName: "",
			rsvpId: "",
		},
		errors: {},
		saveTheDateDetail: {
			isSent: false,
			sentMethod: "",
			dateSent: "",
			rsvp: "",
			isOnline: false,
			isInvitationSent: false,
			invitationSentMethod: "",
			dateInvitationSent: "",
		},
		/*
  self.weddingGuestId = ""
		 self.saveTheDateDetail = SaveTheDateModel()
  self.isSent = false
        self.sentMethod = ""
        self.dateSent = Date()
        self.rsvp = ""
        self.isOnline = false
        self.isInvitationSent = false
        self.invitationSentMethod = ""
        self.dateInvitationSent = Date()

		*/
	};

	//Joi validation schema
	schema = {
		guestName: Joi.string().required().label("Guest Name"),
		rsvpId: Joi.string().optional().label("RSVP Option"),
	};

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
		//this.data.rsvp = "In-Person";
		//alert(this.data.rsvp);
		toast.configure();
		//toast.success("Error");
		//get the current user profile
		//when connected to the network
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log(`Profle ${profile.lastName}`);

		this.setState({ currentProfile: profile });

		//TODO
		//get the current SaveTheDateData for the current user
		const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
			profile.weddingGuestId,
		);
		console.log(saveTheDateDetail);
		this.setState({ saveTheDateDetail: saveTheDateDetail });
		/*const weddingPartyId = this.props.match.params.party;

		const { data: weddingParty } = await fetchTargetWedding(weddingPartyId);
		console.log(weddingParty);
		this.setState({ weddingParty: weddingParty });*/
	}

	doSubmit = async () => {
		//handle the submission
		try {
			//get the curriculum for the target course
			//toast.error("CourseId: " + this.state.data.courseId);

			//alert(this.state.data.guestName);
			//console.log(targetRsvp.rsvp_name);
			//toast.warning(this.state.data.rsvpId);
			/*const targetRsvp = this.state.rsvp_options.filter(
				(rsvp) => rsvp.rsvp_id === this.state.data.rsvpId,
			);*/
			if (this.state.data.rsvpId !== "") {
				const targetRsvp = this.state.rsvp_options[this.state.data.rsvpId];

				console.log(targetRsvp.rsvp_name);
				if (targetRsvp.rsvp_name === "In-Person") {
					toast.info(
						"we are sorry we are no longer accepting in-person guests.",
					);
					return;
				}
				if (targetRsvp.rsvp_name !== "") {
					var temp_save = this.state.saveTheDateDetail;
					//update the save the date
					const saveTheDate = {
						isSent: temp_save.isSent,
						sentMethod: temp_save.sentMethod,
						dateSent: temp_save.dateSent,
						rsvp: targetRsvp.rsvp_name,
						isOnline: temp_save.isOnline,
						isInvitationSent: temp_save.isInvitationSent,
						invitationSentMethod: temp_save.invitationSentMethod,
						dateInvitationSent: temp_save.dateInvitationSent,
					};

					var rsvp_detail = {
						weddingGuestId: this.state.currentProfile.weddingGuestId,
						//save the date details
						saveTheDateDetail: saveTheDate,
					};
					console.log("RSVP Detail Request");
					console.log(rsvp_detail);

					await respondToRsvp(rsvp_detail);
					//if successful redirect to student dashboard where the courses registered for appears etc
					toast.success("Your RSVP Response Successfully Received!");
					//go to student dashboard
					//window.location = "/dashboard";
					//refresh page
					//refresh the page
					const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
						this.state.currentProfile.weddingGuestId,
					);

					this.setState({ saveTheDateDetail: saveTheDateDetail });
					this.props.history.push(`/attendance`);

					//force reload the page
					window.location.reload();
					//set at the top of the screen
					window.scrollTo(0, 0);
				} else {
					//console.log("No Course Curriculum found.");
					toast.error("No RSVP Response found. Must have RSVP selected.");
					return;
				}
			}
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.emailAddress = ex.response.data;

				console.log(ex.response.data);
				toast.error(ex.response.data);
				//update UI
				this.setState({ errors });
			}
		}
	};
	//TODO: Need to show the Zoom link here
	render() {
		const { rsvp_options, saveTheDateDetail } = this.state;
		//Need a TODO list Page Wedding and Registry Page
		return (
			<React.Fragment>
				<div
					className='row'
					style={{
						display: "grid",
						gridTemplateColumns: "auto auto auto auto",
					}}>
					<div
						className=' slider-title-page'
						style={{
							width: "100%",
							height: "50%",
							textAlign: "center",
							display: "block",
							padding: "10%",
						}}>
						{saveTheDateDetail.rsvp == "Online" ||
							(saveTheDateDetail.rsvp == "In-Person" && (
								<section
									id='content'
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										display: "inline-block",
										width: "100%",
										minWidth: "100px",
									}}>
									<h3>
										The <b>Big Day</b> has finally arrived!!!
									</h3>
									<section>
										<h4>
											The ceremony will begin at 4:00 PM New York Time and the
											Zoom call will be started by 3:30 PM{" "}
										</h4>
										<h4>The link for the Zoom streaming is as follows.</h4>

										<p>
											Hi there, Keijaoh Campbell and Marlee Collins is inviting
											you to a scheduled Zoom meeting.
											<br />
											Topic: Keijaoh Campbell and Marlee Collins Wedding
											Ceremony Time: May 14, 2022 04:00 PM Eastern Time (US and
											Canada) Join from PC, Mac, Linux, iOS or Android:
											<br />
											<h2>
												<p>Click the "Ceremony Link button below"</p>{" "}
												<Link
													to={{
														pathname:
															"https://maine.zoom.us/j/84935182415?pwd=RFREWVNpY3lER1RCWUp0SEM0WUtlQT09",
													}}
													target='_blank'>
													Ceremony Link
												</Link>
											</h2>
											<h3>
												Pasword <b>{`onelove<3`}</b>
											</h3>
											<h4> Other options for joining:</h4>
											<p>
												Or Telephone: US: +1 312 626 6799 or +1 646 876 9923 or
												+1 301 715 8592 or +1 346 248 7799 or +1 408 638 0968 or
												+1 669 900 6833 or +1 253 215 8782 Meeting ID: 849 3518
												2415 From a Polycom or other H.323 room system that is
												not a member of a video conference, click call on the
												remote and dial one of the following IP addresses
												followed by # the meeting ID and # again: 162.255.36.11
												(US East) 162.255.37.11 (US West) Meeting ID: 849 3518
												2415 Password: 934832715.
											</p>
										</p>
									</section>
								</section>
							))}
						{/*<section
							id='content'
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								display: "inline-block",
								width: "100%",
								minWidth: "100px",
							}}>
							<h2>Attendance Managent Dashboard</h2>
							<section>
								<p>
									RSVP: The response deadline has passed and as such only{" "}
									<b>Online</b> guest are being accepted at this time.
								</p>
								<p>
									{" "}
									If you want rather to use a mobile app, the iOS link can be
									found here
								</p>
								<p></p>
								<p>
									Your Current RSVP Response:{" "}
									<b>
										<i>{saveTheDateDetail.rsvp}</i>
									</b>
								</p>
								<p>
									<b>
										<i>iOS Guest Portal Link</i>
									</b>
									<br />

									<Link
										to={{
											pathname:
												"https://apps.apple.com/us/app/keimar-wedding-guest-app/id1613342695",
										}}
										target='_blank'>
										<img src={store_img_2} />
									</Link>
								</p>
							</section>
							<form onSubmit={this.handleSubmit} style={{ padding: "0%" }}>
							
								{this.renderSelectRsvpOption(
									"rsvpId",
									"RSVP Option Selector",
									rsvp_options,
								)}

								{this.renderTextInputLabel(
									"guestName",
									"Guest Name",
									"text",
									"form-control form-control-lg ajax-input ",
									"Sign with your Name Please",
									"form-label",
									true,
									"la la-envelope input-icon",
								)}

								{this.renderButton(
									"Send RSVP",
									"btn btn-success   button large ",
								)}
							</form>
						</section>*/}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AttendanceManagement;
