import logo from "./logo.svg";
import "./App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import React, { Component } from "react";
import HomePage from "./components/public/homepage";
import NavBar from "./components/reusable/navigationbar";
import GeneralFooter from "./components/reusable/footer";
import AboutGroom from "./components/about/aboutgroom";
import AboutBride from "./components/about/aboutbride";
import Memories from "./components/memories/memories";
import TheVenue from "./components/venue/thevenue";
import GroomsMen from "./components/wedding/groomsmen/groomsmen";
import BridesMaid from "./components/wedding/bridesmaid/bridesmaid";
import Blogs from "./components/blogs/blogs";
import BlogDetail from "./components/blogs/blogdetail";
import NotFound from "./components/error/notfound";
import OurStory from "./components/blogs/ourstory";
import WeddingPartyDetail from "./components/wedding/weddingpartydetail/weddingpartydetail";
import Memory from "./components/memories/memory";
import WeddingRegistry from "./components/registry/weddingregistry";
import Login from "./components/authentication/login";
import AttendanceManagement from "./components/portal/attendanceManagement";
import LogOut from "./components/authentication/logout";

import {
	getCurrentUser,
	getCurrentUserProfile,
	getJwt,
} from "./services/authentication/guestauthen";
import ProtectedRoute from "./components/reusable/security/protectedRoute";
import PlusOneGuestManagement from "./components/portal/plusoneguestmanagement";
import MyDish from "./components/portal/dish/mydish";
import SelectMainDish from "./components/portal/dish/selectMainDish";
import AddPlusOneGuest from "./components/portal/plusOne/addplusoneguest";
import AddPlusOneGuestFood from "./components/portal/plusOne/addPlusOneGuestFood";
import { fetchSaveTheDateInfo } from "./services/rsvp/rsvpservice";
import OnlineGuest from "./components/portal/online/onlineguest";
import MobileApp from "./components/portal/mobile/mobileapp";
import SeatMap from "./components/portal/seating/seatingmap";

class App extends Component {
	state = {
		guest: {
			firstName: "",
			lastName: "",
		},
		saveTheDateDetail: {
			isSent: false,
			sentMethod: "",
			dateSent: "",
			rsvp: "",
			isOnline: false,
			isInvitationSent: false,
			invitationSentMethod: "",
			dateInvitationSent: "",
		},
		footer_img:
			"https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Wedding%2Fbride_groom%2Fbw-105.jpeg?alt=media&token=4838b9f8-68ff-491b-8a59-f49452f032e9",
	};

	async componentDidMount() {
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log("Profle");

		const currentUserProfile = {
			firstName: profile.firstName,
			lastName: profile.lastName,
		};
		console.log(currentUserProfile);
		//alert(currentUserProfile.firstName);
		this.setState({ guest: currentUserProfile });
		//show footer when student == null or not signed in fetchSaveTheDateInfo

		const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
			profile.weddingGuestId,
		);

		this.setState({ saveTheDateDetail: saveTheDateDetail });

		//alert(saveTheDateDetail.rsvp);
	}

	render() {
		const { footer_img, guest, saveTheDateDetail } = this.state;
		return (
			<React.Fragment>
				<NavBar guest={guest} saveTheDateDetail={saveTheDateDetail} />
				<div className='content'>
					<Switch>
						<Route path='/' exact component={HomePage} />
						<Route path='/aboutgroom' component={AboutGroom} />
						<Route path='/aboutbride' component={AboutBride} />
						{/*<Route path="/memories" component={Memories} />*/}
						<Route path='/memories' component={Memory} />
						{/*TheVenue  shows the venue */}
						<Route path='/thevenue' component={TheVenue} />
						<ProtectedRoute path='/registry' component={WeddingRegistry} />
						<Route path='/groomsmen' component={GroomsMen} />
						<Route path='/bridesmaid' component={BridesMaid} />
						<Route path='/blogs' component={Blogs} />
						<Route path='/blogdetail/:blogId' component={BlogDetail} />
						<Route path='/weddingparty/:party' component={WeddingPartyDetail} />
						<Route path='/ourstory' component={OurStory} />
						<Route path='/login' component={Login} />
						<ProtectedRoute
							path='/plusoneguest'
							component={PlusOneGuestManagement}
						/>
						<ProtectedRoute
							path='/attendance'
							component={AttendanceManagement}
						/>
						<ProtectedRoute path='/mydish' component={MyDish} />
						<ProtectedRoute path='/selectmaindish' component={SelectMainDish} />
						<ProtectedRoute
							path='/addplusoneguest'
							component={AddPlusOneGuest}
						/>
						<ProtectedRoute
							path='/addplusoneguestfood/:weddingGuestPlusOneId'
							component={AddPlusOneGuestFood}
						/>
						<ProtectedRoute path='/mobileapp' component={MobileApp} />

						<ProtectedRoute path='/seatmap' component={SeatMap} />
						<ProtectedRoute path='/onlineguest' component={OnlineGuest} />
						<Route path='/logout' component={LogOut} />
						<Route path='/notfound' component={NotFound} />
						<Redirect to='/notfound' />
					</Switch>
				</div>
				{footer_img && <GeneralFooter footer_img={footer_img} />}
			</React.Fragment>
		);
	}
}

export default App;
