import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import {
	getCurrentUser,
	getCurrentUserProfile,
} from "../../../services/authentication/guestauthen";
import Form from "../../reusable/other/form/form";
import Joi from "joi-browser";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchSaveTheDateInfo } from "../../../services/rsvp/rsvpservice";
import {
	fetchAllWeddingMenuOptions,
	savePlusOneGuest,
	sendMainGuestMenu,
} from "../../../services/rsvp/guestManagement";
import { Link } from "react-router-dom";
import uuid from "react-uuid";

class AddPlusOneGuest extends Form {
	state = {
		showMainDishForm: false,
		/*
["", "", "","","","", ""]

        */
		plus_one_relation_options: [
			{
				relation_id: "0",
				relation_name: "Husband",
			},
			{
				relation_id: "1",
				relation_name: "Wife",
			},
			{
				relation_id: "2",
				relation_name: "Partner",
			},
			{
				relation_id: "3",
				relation_name: "Boyfriend",
			},
			{
				relation_id: "4",
				relation_name: "Girlfriend",
			},
			{
				relation_id: "5",
				relation_name: "Child",
			},
			{
				relation_id: "6",
				relation_name: "Other",
			},
		],
		currentProfile: {
			weddingGuestId: "",
		},
		data: {
			relation_id: "",
			firstName: "",
			lastName: "",
		},
		errors: {},
		saveTheDateDetail: {
			isSent: false,
			sentMethod: "",
			dateSent: "",
			rsvp: "",
			isOnline: false,
			isInvitationSent: false,
			invitationSentMethod: "",
			dateInvitationSent: "",
		},

		foodOptions: [
			{
				menu_item_id: "",
				menu_item_name: "",
				menu_item_detail: "",
				menu_item_image_urls: [""],
				menu_item_category: "",
			},
		],

		selectFoodOption: {
			menu_item_id: "",
			menu_item_name: "",
			menu_item_detail: "",
			menu_item_image_urls: [""],
			menu_item_category: "",
		},
	};

	//Joi validation schema
	schema = {
		firstName: Joi.string().required().label("Plus One's First Name"),
		lastName: Joi.string().required().label("Plus One's Last Name"),
		relation_id: Joi.string().optional().label("Relation Option"),
	};

	async componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);
		//this.data.rsvp = "In-Person";
		//alert(this.data.rsvp);
		toast.configure();
		//toast.success("Error");
		//get the current user profile
		//when connected to the network
		const guest = await getCurrentUser();

		this.setState({ guest });
		console.log(guest);

		//get the student profile here
		const { data: profile } = await getCurrentUserProfile(guest.weddingGuestId);
		console.log(`Profle ${profile.lastName}`);

		this.setState({ currentProfile: profile });

		//TODO
		//get the current SaveTheDateData for the current user
		const { data: saveTheDateDetail } = await fetchSaveTheDateInfo(
			profile.weddingGuestId,
		);
		console.log(saveTheDateDetail);
		this.setState({ saveTheDateDetail: saveTheDateDetail });

		//fetch all the menu options

		//const weddingPartyId = this.props.match.params.party;

		const { data: foodOptions } = await fetchAllWeddingMenuOptions();
		console.log(foodOptions);
		this.setState({ foodOptions: foodOptions });
	}

	doSubmit = async () => {
		//handle the submission
		try {
			const { data, selectFoodOption, currentProfile } = this.state;
			const temp_profile_img =
				"https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/defaultImages%2FNoImageFound.jpg1_.png?alt=media&token=75d79486-de11-4096-8b7d-d8b1945d71cf";

			if (data.relation_id !== "") {
				const targetRsvp =
					this.state.plus_one_relation_options[this.state.data.relation_id];

				const plusOneGuestDetail = {
					weddingGuestPlusOneId: uuid(),
					weddingGuestId: currentProfile.weddingGuestId,
					firstName: data.firstName,
					lastName: data.lastName,
					relationToMainGuest: targetRsvp.relation_name,
					profileImageUrl: temp_profile_img,
					isBanned: false,
				};

				await savePlusOneGuest(plusOneGuestDetail);
				//if successful redirect to student dashboard where the courses registered for appears etc
				toast.success(`${data.firstName} Successfully Added!`);
				//go to student dashboard
				window.location = "/plusoneguest";
			}
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.emailAddress = ex.response.data;

				console.log(ex.response.data);
				toast.error(ex.response.data);
				//update UI
				this.setState({ errors });
			}
		}
	};

	goBackToPlusOne() {
		window.location = "/plusoneguest";
	}

	render() {
		const { plus_one_relation_options } = this.state;
		//Need a TODO list Page Wedding and Registry Page
		return (
			<React.Fragment>
				<div
					className='row'
					style={{
						//	display: "grid",
						gridTemplateColumns: "auto auto auto auto",
					}}>
					<div
						className=' slider-title-page'
						style={{
							width: "100%",
							height: "50%",
							textAlign: "center",
							display: "block",
						}}>
						<section
							id='content'
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								display: "inline-block",
								width: "70%",
								minWidth: "100px",
							}}>
							<div
								style={{
									textAlign: "right",
									paddingTop: "5%",
									paddingRight: "1%",
								}}>
								<Link
									className='btn btn-info'
									onClick={() => this.goBackToPlusOne()}>
									<b>Go Back </b>
								</Link>
							</div>
							{/* Dish start*/}
							<React.Fragment>
								{/* BLOG-ITEM-1 Make reuseable start */}
								<div className='row'>
									<div className='blog-wrapper col-md-offset-1 col-md-5'>
										<form
											onSubmit={this.handleSubmit}
											style={{ padding: "0%", width: "100%" }}>
											{this.renderTextInputLabel(
												"firstName",
												"First Name",
												"text",
												" form-control form-control-lg ajax-input",
												"Your Plus One Guest First Name",

												true,
												"la la-envelope input-icon",
											)}

											{this.renderTextInputLabel(
												"lastName",
												"Last Name",
												"text",
												" form-control form-control-lg ajax-input",
												"Your Plus One Guest Last Name",

												true,
												"la la-envelope input-icon",
											)}

											{this.renderSelectRelationOption(
												"relation_id",
												"Relation Option Selector",
												plus_one_relation_options,
											)}

											{this.renderButton(
												"Add Guest",
												"btn btn-success   button medium ",
											)}
										</form>
									</div>
								</div>
								{/* Make reusable*/}
							</React.Fragment>
							{/* Dish end*/}
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AddPlusOneGuest;
