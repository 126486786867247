import React, { useState, useCallback } from 'react'
import { render } from 'react-dom'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import '../../styletheme/css/style.css'
import '../../styletheme/css/memories/memories.css'

function Memories({memoriesImages}) {
  
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <section
      id="content"
      className="memoriesSpacing"
      style={{ paddingBottom: '5%' }}
    >
      {/* CONTAINER-1 */}
      <div class="container">
        {/* TITLE */}
        <div class="row">
          <div class="col-md-offset-3 col-md-6 text-center">
            <div class="page-title">
              <h1>Memories</h1>

              <div class="heart-divider">
                <span class="grey-line"></span>
                <i class="de-icon-heart pink-heart"></i>
                <i class="de-icon-heart grey-heart"></i>
                <span class="grey-line"></span>
              </div>
              <p>
                Share in our memories we have made so far and will continue to make together.
              </p>
            </div>
          </div>
        </div>
        {/* END of TITLE */}
      </div>
      {/* END of CONTAINER-1 */}

      {/* CONTAINER-1 */}
      <div class="container">
        <Gallery
          photos={memoriesImages}
          onClick={openLightbox}
          style={{ paddingTop: '5%', paddingBottom: '5%' }}
        />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={memoriesImages.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  )
}

export default Memories
