import http from "../../services/httpService";
import apiEndpoint from "../../constants/config.json";

//create method create_new_check_out_order
//TODO fetch everything by couple ID so that the application can be universal
export function fetchBrideMaids() {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/weddingPartyManagement/api/fetch_all_brides_maid/";
	return http.get(fullApiEndpoint);
}

export function fetchTargetWedding(weddingpartyId) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/weddingPartyManagement/api/fetch_wedding_party_by_id/" +
		weddingpartyId;
	return http.get(fullApiEndpoint);
}

export function fetchGroomsMen() {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/weddingPartyManagement/api/fetch_all_grooms_men/";
	return http.get(fullApiEndpoint);
}
