import http from "../../services/httpService";
import apiEndpoint from "../../constants/config.json";

export function fetchMainGuestMenuById(weddingGuestId) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/guestManagement/api/fetch_main_guest_menu_by_id/" +
		weddingGuestId;
	return http.get(fullApiEndpoint);
}

export function fetchAllWeddingMenuOptions() {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/weddingFoodManagement/api/fetch_all_wedding_menu_items";
	return http.get(fullApiEndpoint);
}

export async function sendMainGuestMenu(mainGuestMenuOption) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint + "/guestManagement/api/update_guest_main_course";

	return http.put(fullApiEndpoint, mainGuestMenuOption);
}

//Plus one section fetch_all_guest_plus_ones

export function fetchAllPlusOnesForGuest(weddingGuestId) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/guestPlusOneManagement/api/fetch_all_guest_plus_ones/" +
		weddingGuestId;
	return http.get(fullApiEndpoint);
}

export async function savePlusOneGuest(plusOneGuest) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/guestPlusOneManagement/api/create_new_plus_one_guest";

	return http.post(fullApiEndpoint, plusOneGuest);
}

export function findPlusOneProfile(weddingGuestPlusOneId) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/guestPlusOneManagement/api/fetch_plus_one_guest_by_id/" +
		weddingGuestPlusOneId;
	return http.get(fullApiEndpoint);
}

export async function sendPlusOneGuestFoodMenu(plusOneGuestMenuOption) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/guestPlusOneManagement/api/update_plus_one_guest_main_course";

	return http.put(fullApiEndpoint, plusOneGuestMenuOption);
}

//danger
export function removePlusOneGuestProfile(weddingGuestPlusOneId) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/guestPlusOneManagement/api/delete_plus_one_guest/" +
		weddingGuestPlusOneId;
	return http.delete(fullApiEndpoint);
}

export async function sendInterestInMobileApp(guestInterest) {
	const fullApiEndpoint =
		apiEndpoint.apiEndpoint +
		"/weddingTodoManagement/api/add_interest_user_in_mobile_app";

	return http.post(fullApiEndpoint, guestInterest);
}




