
import React, { Component } from 'react'

import notfound from "../../styletheme/images/404.svg";
import { Link,useHistory } from 'react-router-dom';


function NotFound()
{
   


        const history = useHistory();
        return(
            
            <React.Fragment>

               
       {/* ERROR PAGE */}
        <section className="bg-home d-flex align-items-center" style={{paddingBottom: "10%",paddingTop: "10%",display: 'flex',justifyContent: 'center'}}>
        <br/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 text-center">
                        <img src={notfound} className="img-fluid" alt=""/>
                        <div className="text-uppercase ">Oh ! no, Page Not Found</div>
                       
                        <p className="text-muted para-desc mx-auto">
                            Please go back to the Home Page and try again.
                        </p>
                    </div>{/*end col*/}
                </div>{/*end row*/}

                <div className="row">
                    <div className="col-md-12 text-center">  
                        <Link  onClick={() => history.goBack()} className="btn btn-outline-primary mt-4">Go Back</Link>
                        <Link to="/" className="btn btn-primary mt-4 ms-2">Go To Home</Link>
                    </div>{/*end col*/}
                    <br/>
                </div>{/*end row*/}
            </div>{/*end container*/}
          
        </section>{/*end section*/}
       {/* ERROR PAGE */}
            </React.Fragment>

    )
    
}

export default NotFound;