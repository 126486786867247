import React, { Component } from 'react'
import '../../../styletheme/css/weddingparty/weddingparty.css'
import { NavLink, Link } from 'react-router-dom'
import { fetchGroomsMen } from '../../../services/weddingparty/weddingPartyService'

class GroomsMen extends React.Component {

	state={
		groomsmen:[{
			weddingpartyId: "",
	        adminId: "",
	        firstName: "",
	        userBioInfo: "",
	        lastName: "",
	        weddingPartyRole: "",
	        profileImageUrl: "",
	        is_brides_maid: true,

		}]
	  }
	  async componentDidMount() {     
		//set at the top of the screen
		window.scrollTo(0, 0);
	
		const {data: groomsmen} = await fetchGroomsMen();
	  console.log(groomsmen);
		this.setState({groomsmen: groomsmen})
	
	
	
	 }


  render() {

	const {groomsmen} = this.state;

    return (
		<React.Fragment>
        {/*CONTENT SECTION*/}
        <section id="content">
        	
            {/* CONTAINER-1 */}
        	<div className="container weddingPartyHeaderSpacing">            
            	
                {/* TITLE */}
                <div className="row">
                    <div className="col-md-offset-3 col-md-6 text-center">
                    
                        <div className="page-title">
                        	<h1>Groomsmen</h1>
                                                       
                        	<div className="heart-divider">
                            	<span className="grey-line"></span>
                            	<i className="de-icon-heart pink-heart"></i>
                            	<i className="de-icon-heart grey-heart"></i>
                            	<span className="grey-line"></span>
                        	</div>
                            <p>
                        		Meet the groom's wedding party.
							</p>
						</div>
                        
                	</div>
				</div>{/* END of TITLE */}                
                
  			</div>{/* END of CONTAINER-1 */}
           
            
            {/* CONTAINER-2 */}
           	<div className="container">
                <div className="row">                                            
              		<div className="col-md-offset-1 col-md-10">
                        
                        {/* CONTAINER-FLUID */}
                        <div className="container-fluid">
                        
                        		{/* GROOMSMEN */}
                            	<div className="row">
                                
								{groomsmen.map((value,index)=>(
                                	<div key={index} className="groomsmen-wrapper col-md-4 col-sm-4">
                                    
                                    	{/*PHOTO-ITEM*/}
                        				<div className="photo-item animation fadeIn">
								
                             				{/*PHOTO*/}
                                			<img src={value.profileImageUrl} alt="" className="hover-animation image-zoom-in"/>
                                            
                               				{/*PHOTO OVERLAY*/}
                           					<div className="layer wh100 hidden-black-overlay hover-animation fade-in">
                            				</div>{/*END of PHOTO OVERLAY*/}
                                				                                    
                                     		{/*ICON LINK*/}
                                     		<div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                                    			<div className="alignment">
                                    				<div className="v-align center-middle">
													<Link to={`/weddingparty/${value.weddingpartyId}`}>
                                     					<span className="de-button outline medium">
                                                			{value.firstName}
                                                		</span>
                                                	</Link>
                                      				</div>
                                        		</div>
                                    		</div>{/*END of ICON LINK*/}
							
										</div>{/*END of PHOTO-ITEM*/} 
                                        
                                        {/* TITLE & EXCERPT */}
                                        <div className="title-excerpt animation fadeIn text-center">
                                        	<div className="de-icon circle very-small-size custom-heart-icon">
            									<i className="de-icon-heart"></i>
            								</div>
											<h3><Link to={`/weddingparty/${value.weddingpartyId}`}>{value.firstName} {value.lastName}</Link></h3>
                                        	<h4><Link to={`/weddingparty/${value.weddingpartyId}`}>- {value.weddingPartyRole} -</Link></h4>
                                            <p>{value.userBioInfo.substring(0,100)}...</p>
                                        </div>
                                        {/* END of TITLE & EXCERPT */}
                                         
                                    </div>
                                    
								))}
                                   
           						</div> {/* END of GROOMSMEN */}
                            
               			</div>{/* END of CONTAINER FLUID */}
                    
   					</div>                     
  				</div>                  
			</div>{/* END OF CONTAINER-2 */}
            
        </section>{/* END OF CONTENT SECTION */}
        
        
        


      </React.Fragment>
    )
  }
}

export default GroomsMen
